import cn from 'classnames';
import { useState } from 'react';
import { ImageButton } from '../../components/AppButtons';
import css from './Popup.module.scss';

export const PopupSubBox = ({title, isExpandable, largeAsDefault, compact, className = css.subbox, cssPackage = css, children}) => {
    
    const [isExpanded, setIsExpanded] = useState(largeAsDefault);
    
    return (
        <div className={cn(className, {[cssPackage.expanded]: isExpanded || largeAsDefault})}>
            <div className={cssPackage.header}>
                <h3>{title}</h3>
                { isExpandable && (<ImageButton onClick={ () => {setIsExpanded(!isExpanded)} } className={ css.chart__fullScreen }/>) }
            </div>
            <div className={cn(cssPackage.body, {[cssPackage.expanded]: isExpanded || largeAsDefault}, {[cssPackage.compact]: compact})}>
                {children}
            </div>
            
        </div>
    )
}