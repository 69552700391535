import { APIResponseType, baseApiInstance } from './api';

interface GetSubmittedReportStatuses extends APIResponseType {
    data: Array<SubmittedReportStatus>
}

interface GetReportStatuses extends APIResponseType {
    data: Array<ReportStatus>
}

interface PostUpload extends APIResponseType {
    statusCode: number
    messages: Array<string>
}

export const uploadAPI = {
    postUpload(request: PostUploadRequestDataType) {
        return baseApiInstance().post('process', request)
            .then(res => res.data) as Promise<PostUpload>;

    },
    postDeleteAllTrades() {
        return baseApiInstance().post('delete_all_trades')
            .then(res => res.data) as Promise<any>;
    },
    postDeleteTradeFile(fileId: DeleteFileRequest) {
        return baseApiInstance().post('delete_trade_file', fileId)
            .then(res => res.data) as Promise<any>;
    },
    getSubmittedReportStatuses() {
        return baseApiInstance().get<GetSubmittedReportStatuses>('submitted_reports')
        .then(res => res.data) as Promise<GetSubmittedReportStatuses>;
    },
    getReportStatuses() {
        return baseApiInstance().get<GetReportStatuses>('reports')
        .then(res => res.data) as Promise<GetReportStatuses>;
    },
};