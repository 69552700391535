import { ReactSVG } from 'react-svg';
import cn from 'classnames';

import { withTheme } from '../../hoc/withTheme';

import exportImg from '../../assets/images/svg/export.svg';

import css from './ExportButton.module.scss';

const ExportButton = ({ theme, exportParams, onClickFunction, text }) => (
	<div
		className={ cn(css.exportBtn, css[theme]) }
		onClick={ () => {
			onClickFunction()
		} }
	>
		<figure className={ css.exportBtn__icon }>
			<ReactSVG src={ exportImg }/>
			<span className={ css.exportBtn__num }></span>
		</figure>
		<div className={ css.exportBtn__content }>
			<p className={ cn('text', css.exportBtn__title) }>{text}</p>
			<p className={ cn('text', css.exportBtn__subtitle) }>Click Here</p>
		</div>
	</div>
);

export default withTheme(ExportButton);
