import React from "react";
import { NavLink } from "react-router-dom";
import { ReactSVG } from "react-svg";
import cn from "classnames";
import { useDispatch } from "react-redux";

import { withTheme } from "../../../hoc/withTheme";
import { appActions } from "../../../redux/app-reducer/app-reducer";

import summaryImg from "../../../assets/images/svg/summary.svg";
import tradesImg from "../../../assets/images/svg/trades.svg";
import uploadImg from "../../../assets/images/svg/upload.svg";
import powerbiImg from "../../../assets/images/svg/900px-New_Power_BI_Logo.svg";
// import metricsImg from '../../../assets/images/svg/metrics.svg';
// import rankingsImg from '../../../assets/images/svg/rankings.svg';
// import analyticsImg from '../../../assets/images/svg/analytics.svg';
// import algosImg from '../../../assets/images/svg/algos.svg';
// import alertsImg from '../../../assets/images/svg/alerts.svg';
// import reportsImg from '../../../assets/images/svg/reports.svg';
// import settingsImg from '../../../assets/images/svg/settings.svg';

import css from "./MenuList.module.scss";

const menuList = [
  { label: "TCA Summary", link: "/", icon: summaryImg },
  // { label: 'Efficiency Metrics', link: '/efficiency-metrics', icon: metricsImg },
  // { label: 'Rankings', link: '/rankings', icon: rankingsImg },
  // { label: 'Fund Analytics', link: '/fund-analytics', icon: analyticsImg },
  // { label: 'Algos', link: '/algos', icon: algosImg },
  { label: "Trades", link: "/trades", icon: tradesImg },
  // { label: 'Alerts', link: '/alerts', icon: alertsImg },
  { label: "Upload Files", link: "/upload", icon: uploadImg },
  // { label: 'Reports', link: '/reports', icon: powerbiImg},
  // { label: 'Reports', link: '/reports', icon: reportsImg },
  // { label: 'Settings', link: '/settings', icon: settingsImg },
];

const MenuList = ({
  isMenuActive,
  setIsMenuActive,
  desktop,
  mobile,
  theme,
}) => {
  const dispatch = useDispatch();

  const handleMenuItem = ({ label }) => {
    setIsMenuActive(false);
    dispatch(appActions.setCurrentPageTitle(label));
  };

  return (
    <nav
      className={cn(css.nav, css[theme], {
        [css.active]: isMenuActive,
        [css.desktop]: desktop,
        [css.mobile]: mobile,
      })}
    >
      <ul style={{ float: "right" }} className={css.navList}>
        {menuList.map((item, idx) => (
          <li
            key={idx}
            className={css.navListItem}
            onClick={() => handleMenuItem(item)}
          >
            <NavLink exact to={item.link} activeClassName={css.active}>
              <figure>
                <ReactSVG src={item.icon} />
              </figure>
              <span>{item.label}</span>
            </NavLink>
          </li>
        ))}
      </ul>
    </nav>
  );
};

export default withTheme(MenuList);
