import React from 'react';
import cn from 'classnames';

import { withTheme } from '../../hoc/withTheme';
import { numberWithCommas } from '../../utils/helpers';
import css from './Summary.module.scss';

const SummaryTable = ({ title, data, theme }) => (
	<div className={ cn(css.summaryTable, css[theme]) }>
		<div className={ css.summaryTable__header }>
			<p className={ css.summaryTable__title }>{ title }</p>
		</div>
		<div className={ css.summaryTable__content }>
			<div className={ css.summaryTable__row }>
				{
					(data || []).map((item, idx) => (
						<TableCell key={ idx } { ...item } />
					))
				}
			</div>
		</div>
	</div>
);

const TableCell = ({ name, value }) => (
	<div className={ css.summaryTable__td }>
		<p className={ css.summaryTable__tdValue }>{ numberWithCommas(value) }</p>
		<p className={ cn('text', css.summaryTable__tdTitle) }>{ name }</p>
	</div>
);

export default withTheme(SummaryTable);
