import React, { useState } from 'react';
import { ReactSVG } from 'react-svg';
import cn from 'classnames';
import { useDispatch, useSelector } from 'react-redux';

import { selectIsFilterActive } from '../../redux/app-reducer/app-selector';
import { appActions } from '../../redux/app-reducer/app-reducer';
import { withTheme } from '../../hoc/withTheme';

import MenuList from './MenuList/MenuList';
import MainFilter from './MainFilter/MainFilter';

import logoImg from '../../assets/images/svg/logo.svg';
import filterImg from '../../assets/images/svg/filter.svg';
import menuImg from '../../assets/images/svg/menu.svg';

import css from './header.module.scss';

const Header = ({ theme }) => {
	const [isMenuActive, setIsMenuActive] = useState(true);
	const isFilterActive = useSelector(selectIsFilterActive);
	const dispatch = useDispatch();

	const handleFilter = () => {
		dispatch(appActions.setIsFilterActive(!isFilterActive));
		setIsMenuActive(false);
	};

	const handleMenu = () => {
		setIsMenuActive(!isMenuActive);
		dispatch(appActions.setIsFilterActive(false));
	};

	return (
		<>
			<header className={ cn(css.header, css[theme]) }>
				<button
					className={ cn(css.filterBtn, css[theme], { [css.active]: isFilterActive }) }
					onClick={ handleFilter }
				>
					<ReactSVG src={ filterImg }/>
				</button>
				<figure className={ css.header__logo }>
					<ReactSVG src={ logoImg }/>
				</figure>
				<button
					className={ cn(css.menuBtn, css[theme], { [css.active]: isMenuActive }) }
					onClick={ handleMenu }
				>
					<ReactSVG src={ menuImg }/>
				</button>
				<MenuList
					isMenuActive={ isMenuActive }
					setIsMenuActive={ setIsMenuActive }
					desktop
				/>
			</header>
			<MenuList
				isMenuActive={ isMenuActive }
				setIsMenuActive={ setIsMenuActive }
				mobile
			/>
			<MainFilter isFilterActive={ isFilterActive }/>
		</>
	);
};

export default withTheme(Header);
