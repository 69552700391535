import { useCallback, useEffect, useState } from 'react';
import DateRangePicker from '@wojtekmaj/react-daterange-picker';
import cn from 'classnames';
import { withTheme } from '../../hoc/withTheme';
import css from './AppDatepicker.module.scss';
import { FilterHeader } from '../AppSelect/FilterHeader';

const AppDatepicker = ({
						   title, name, isReset, theme, onChange, onCalendarOpen, onCalendarClose, initialValue, ...otherProps
					   }) => {
	const [isFocused, setIsFocused] = useState(false);
	const [calendarValue, onCalendarChange] = useState(null);

	const [reset, setReset] = useState(false);
	const [changed, setChanged] = useState(false);

	const handleChange = useCallback(dates => {
		onCalendarChange(dates);
		onChange({ name, dates });

		if(!reset) {
			setChanged(true);
		}
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [name, onChange]);

	const handleCalendarOpen = (props) => {
		setIsFocused(true);
		onCalendarOpen && onCalendarOpen(props);
	};

	const handleCalendarClose = (props) => {
		setIsFocused(false);
		onCalendarClose && onCalendarClose(props);
	};

	const selectNewTimeRange = newRange => {
		onCalendarChange(newRange);
		onChange({name, dates: newRange});
		console.log(calendarValue);
	}

	const getTodaysDateMinusTime = (number, unit) => {
		var todaysDate = new Date();
		switch (unit){
			case "days":
				var pastDate = todaysDate.getDate() - number;
				todaysDate.setDate(pastDate);
				break;
			case "months":
				todaysDate.setMonth(todaysDate.getMonth() - number);
				break;
			case "years":
				todaysDate.setFullYear(todaysDate.getFullYear() - number);
				break;
			default:
				todaysDate.setDate(pastDate);
		}

		return todaysDate;
	}

	useEffect(() => {
		if (reset) {
			setReset();
			handleChange(null);
		}
	}, [reset, handleChange]);

	return (
		<div
			className={ cn(css.datepicker, theme, css[theme], 'app-datepicker', 'filter-item', { [css.focus]: isFocused }) }>
			<FilterHeader title={title} name={name} setReset={setReset} changed={changed} setChanged={setChanged}/>
			<DateRangePicker
				onChange={ handleChange }
				onCalendarOpen={ handleCalendarOpen }
				onCalendarClose={ handleCalendarClose }
				value={ !calendarValue ? initialValue : calendarValue }
				clearIcon={null}
				calendarIcon={ null }
				format='dd MMM y'
				{ ...otherProps }
				// minDate={new Date('2020-12-17T03:24:00')}
			/>
			<div style={{marginBottom: '20px'}}>
					<button onClick={() => {selectNewTimeRange([new Date(), new Date()])}} className={css.timebutton} >TODAY</button>
					<button onClick={() => {selectNewTimeRange([getTodaysDateMinusTime(7, "days"), new Date()])}} className={css.timebutton} >PAST WEEK</button>
					<button onClick={() => {selectNewTimeRange([getTodaysDateMinusTime(1, "months"), new Date()])}} className={css.timebutton} >PAST MONTH</button>
					<button onClick={() => {selectNewTimeRange([getTodaysDateMinusTime(1, "years"), new Date()])}} className={css.timebutton} >PAST YEAR</button>
			</div>
		</div>
		
	);
};

export default withTheme(AppDatepicker);
