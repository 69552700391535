import css from './Upload.module.scss';
import { useState } from 'react';
import { useAppDispatch } from '../../redux/hooks';
import { deleteAllTrades } from '../../redux/upload-reducer/upload-slice';


const DeletionConfirmationPopupContent = ({setOpen}) => {
    const dispatch = useAppDispatch();

    const [isDeleteButtonVisible, setIsDeleteButtonVisible] = useState(false);

    const cancel = () => {
        setOpen(false);
    }

    const deleteAllFiles = () => {
        dispatch(deleteAllTrades())
        setOpen(false);
    }

    const handleChange = (value) => {
        if(value.target.value.toLowerCase() === "delete") {
            setIsDeleteButtonVisible(true);
        } else {
            setIsDeleteButtonVisible(false);
        }
    }

    return (
        <div className={css.deletion_confirmation}>
            <h1><span>CAUTION:</span> Once this process has been started it cannot be undone.</h1>

            <p>Please enter the word <span>DELETE</span> into the box below to unlock the delete button.</p>
            <input type={"text"} onChange={handleChange} ></input>
            <div className={css.button_box}>
                <button className={css.button} onClick={cancel}>
                    CANCEL
                </button>
                {isDeleteButtonVisible && (
                    <button className={css.red_button} onClick={deleteAllFiles}>
                        DELETE
                    </button>
                )}
            </div>
        </div>
    )
}

export default DeletionConfirmationPopupContent;