import axios from 'axios';

// const API_URL: string = 'https://webapi-uat.newchangefx.com/tca';
const API_URL: string = process.env.REACT_APP_API_URL || '';
const POWERBI_URL: string = process.env.REACT_APP_POWERBI_URL || '';

const createBaseApiInstance = (url: string) => {
    const jwt = sessionStorage.getItem('jwt');
    return (
        axios.create({
            baseURL: url,
            headers: {
                'Authorization': 'Bearer ' + jwt,
                'AuthorizationBackup': 'Bearer ' + jwt
        }
    }))
}

export const baseApiInstance = () => createBaseApiInstance(API_URL);
export const powerBiApiInstance = () => createBaseApiInstance(POWERBI_URL);

export interface APIResponseType {
    error?: {

        message: string
        
    }
    response: {
        data: {
            error: {
                message: string
            }
        }
        status: number,
        statusText: string
        message: string,
        code: number
    }
}