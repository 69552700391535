import { Bar } from 'react-chartjs-2';
import { useSelector } from 'react-redux';
import css from '../../Chart/Chart.module.scss';
import { getChartGradients } from '../../../utils/helpers';
import { selectEfficiencyChartsCurrency } from '../../../redux/charts-reducer/charts-selector';

const BarChart = ({ data = [] }) => {
	const textColor = css.textColor;
	const axisColor = css.axisColor;
	const currencySign = useSelector(selectEfficiencyChartsCurrency);

	const chartData = canvas => {
		
		const ctx = canvas.getContext('2d');
		const datasets = (data) => {
            let set = [];
            let numberOfDatasets = data.length;
            let i;
            for (i = 0; i < numberOfDatasets; i++){
                let datapoints = [];
				var j;
				let numberOfData = data.length > 0 ? data[i].data.length : 0;
				for (j = 0; j < numberOfData; j++){
					datapoints.push(Number(data[i].data[j].y));

				}

                let datatitle = data[i].title;
                let { lineColor, gradient } = getChartGradients(ctx, i, numberOfDatasets);
                set.push({
                    label: datatitle,
                    backgroundColor: gradient,
                    borderColor: lineColor,
					stack: i,
                    borderWidth: {
						top: 4,
						right: 0,
						bottom: 4,
						left: 0
					},
                    pointBackgroundColor: lineColor,
                    fill: gradient,
                    borderDash: [2],
                    data: datapoints
                })
            }

            return set;
        }


		return {
			labels: ['Origination','Desk','Submission','Fill'],
			datasets: datasets(data)
		};
	};

	const chartOptions = {
		plugins: { 
            datalabels: {
                display: 'auto',
                color: 'white',
                anchor: 'end',
                align: 'top',
				clamp: true,
				offset: 0,
				formatter: function(value) {
					let valueString = String(value);
					if (valueString[0] === '-'){
						return '-$' + valueString.substring(1).toLocaleString();
					}
					else {
						return '$ ' + String(value).toLocaleString();
					}
				}
            }
        },
		tooltips: {
			mode: 'index',
			intersect: false,
			callbacks: {
				title: function(tooltipItem, data) {
				  return tooltipItem[0].xLabel;
				},
				label: function(tooltipItem, data) {
					let valueString = String(tooltipItem.yLabel);
					if (valueString[0]=== '-'){
						return '-$' + valueString.substring(1).toLocaleString();
					}
					else {
						return '$ ' + valueString.toLocaleString();
					}
				},
			  }
		},
		scales: {
			xAxes: [{
				stacked: true,
				categoryPercentage: 0.9,
				barPercentage: 1,
				gridLines: {
					color: axisColor
				},
				ticks: {
					fontColor: textColor,
					fontSize: 15,
				}
			}, {
				display: false
			}],
			yAxes: [{
				gridLines: {
					color: axisColor,
					borderDash: [2]
				},
				ticks: {
					fontSize: 15,
					fontColor: textColor,
					beginAtZero: true,
					callback: function (value, index, values) {
						return `${ currencySign || '$' } ${ String(value).toLocaleString() }`;
					}
				}
			}]
		}
	};

	return (
		<Bar data={ chartData } options={ chartOptions }/>
	);
};

export default BarChart;
