import { APIResponseType, baseApiInstance } from './api';

interface GetRankingsResponseDataType extends APIResponseType {
    rankingsChart: RankingsChartType
    rankingsTable: RankingsTableType
}

export const rankingsAPI = {
    getRankings() {
        return baseApiInstance().post<GetRankingsResponseDataType>('rankings')
            .then(res => res.data)
            .catch(err => console.log(err)) as Promise<GetRankingsResponseDataType>;
    }
};