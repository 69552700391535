import {rankingsAPI} from '../../api/rankings-api';
import {appActions, AppActionsTypes} from '../app-reducer/app-reducer';
import {BaseThunkType, InferActionsTypes} from "../store";

const RANKINGS_LOADED = 'trades/RANKINGS_LOADED';

const initialState = {
    chart: {} as RankingsChartType,
    table: {} as RankingsTableType
};

type InitialStateType = typeof initialState;

const rankingsReducer = (state = initialState, action: RankingsActionsTypes): InitialStateType => {

    switch (action.type) {
        case RANKINGS_LOADED:
            return {...state, ...action.payload};
        default:
            return state;
    }
};

export const rankingsActions = {
    rankingsLoaded: (chart: RankingsChartType, table: RankingsTableType) => ({
        type: RANKINGS_LOADED,
        payload: {chart, table}
    })
};

type RankingsActionsTypes = InferActionsTypes<typeof rankingsActions>
type ThunkType = BaseThunkType<RankingsActionsTypes | AppActionsTypes>

export const fetchRankings = (): ThunkType => async (dispatch) => {
    try {
        const {rankingsChart, rankingsTable, error} = await rankingsAPI.getRankings();
        if (!error) {
            dispatch(rankingsActions.rankingsLoaded(rankingsChart, rankingsTable));
        } else {
            dispatch(appActions.setErrors(error.message));
        }
    } catch (e) {
        console.log(e);
    }
};

export default rankingsReducer;
