import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { appAPI } from '../../api/app-api';
import { appActions } from './app-reducer';

interface AppStatusState {
    backendDisconnected: boolean,
    stateInfo: Array<StateInfoType>
}

const initialState = {
    backendDisconnected: false,
    stateInfo: [{title: "System Status", value: "Offline", type: "error"}, {title: "Remarks", value: "Cannot connect to NCFX servers", type: "error"}] as Array<StateInfoType>,
} as AppStatusState

export const fetchStateInfo = createAsyncThunk('authentication/fetchStateInfo', async (firstArg: void, thunkApi) =>  {
    try {
        const { data, error } = await appAPI.getStateInfo();

        if (!error) {
            thunkApi.dispatch(appStatusActions.setStateInfo(data));
        } else {
            thunkApi.dispatch(appActions.setErrors(error.message));
        }
    } catch (e) {
        throw e;
    }
})

const appStatusSlice = createSlice({
    name: 'appStatus',
    initialState,
    reducers: {
        setBackendConnected(state) {
            state.backendDisconnected = false;
        },
        setBackendDisconnected(state) {
            state.backendDisconnected = true;
        },
        setStateInfo(state, action: PayloadAction<Array<StateInfoType>>) {
            state.stateInfo = action.payload;
        }
    }
})

export const appStatusActions = appStatusSlice.actions;
export default appStatusSlice.reducer;