import { createSelector } from 'reselect';

import { AppStateType } from "../store";

export const selectEfficiencyCharts = (state: AppStateType) => state.charts.efficiencyCharts;
export const selectEfficiencyChartsCurrency = (state: AppStateType) => state.charts.efficiencyChartsCurrency;

export const selectEfficiencyFiltersNames = createSelector(selectEfficiencyCharts, (efficiencyCharts) => {
	return efficiencyCharts.length ? efficiencyCharts[0].chartData.map(({ name, color }) => ({
		name,
		color
	})) : [];
});

