import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { withTitle } from '../../hoc/withTitle';
import { fetchRankings } from '../../redux/rankings-reducer/rankings-reducer';

import RankingsChart from './RankingsChart';
import RankingsTable from './RankingsTable';

const Rankings = () => {

	const dispatch = useDispatch();

	useEffect(() => {
		dispatch(fetchRankings())
	}, [dispatch]);

	return (
		<div>
			<RankingsChart/>
			<RankingsTable/>
		</div>
	);
};

export default withTitle(Rankings);