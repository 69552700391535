import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import jwtDecode from 'jwt-decode';
import { jwtAPI } from '../../api/app-api';
import { userAPI } from '../../api/user-api';
import { RootState } from '../store';

interface AuthenticationState {
    isUserAuthenticated: boolean
    isUserLockedOut: boolean

    jwtStored: boolean
    userId: number
    jwt: string
    roles: Array<string>
    userName: string
}

const initialState = {
    isUserAuthenticated: false,
    isUserLockedOut: false,

    jwtStored: false,
    userId: 0,
    jwt: "",
    roles: [],
    userName: ""
} as AuthenticationState

export const authenticateUser = createAsyncThunk('authentication/authenticateUser', async (firstArg: void, thunkApi) => {
    try {
        const state = thunkApi.getState() as RootState;

        const roles = jwtDecode<Jwt>(state.authentication.jwt).roles as Array<string>;
        if (state.authentication.jwt === "" || !(roles.includes('TCA'))) {
            thunkApi.dispatch(authenticationActions.setIsUserLockedOut(true));
            thunkApi.dispatch(authenticationActions.setIsUserAuthenticated(false));
        }
        else {
            const response = await userAPI.getCredentialCheck();
            if (response.Status === "Passed") {
                thunkApi.dispatch(authenticationActions.setIsUserAuthenticated(true));
                thunkApi.dispatch(authenticationActions.setIsUserLockedOut(false));
            }
            else {
                thunkApi.dispatch(authenticationActions.setIsUserAuthenticated(false));
                thunkApi.dispatch(authenticationActions.setIsUserLockedOut(true));
            }
            return response;
        }        
    }
    catch (err) {
        thunkApi.dispatch(authenticationActions.setIsUserLockedOut(true));
        thunkApi.dispatch(authenticationActions.setIsUserAuthenticated(false));
    }
    
});

export const fetchJwtToken = createAsyncThunk('authentication/fetchJwtToken', async (firstArg: void, thunkApi) => {
    try {
        const response = await jwtAPI.fetchJwtAuthentication();
        if (response) {
            thunkApi.dispatch(authenticationActions.setJwt(response.Token));
        }
        return response;
    }
    catch (err) {
        throw err;
    }
    
});

const authenticationSlice = createSlice({
    name: 'authentication',
    initialState,
    reducers: {
        setIsUserAuthenticated(state, action: PayloadAction<boolean>) {
            if (state.jwtStored) {
                state.isUserAuthenticated = action.payload;
            }
        },
        setJwt(state, action: PayloadAction<string>) {
            state.jwtStored = true;
            state.jwt = action.payload;
            sessionStorage.setItem('jwt', action.payload);
            const jwtBody = jwtDecode(action.payload) as Jwt;
            state.userId = jwtBody.id;
            state.userName = jwtBody.name;
        },
        setIsUserLockedOut(state, action: PayloadAction<boolean>) {
            state.isUserLockedOut = action.payload;
        }
    },
    extraReducers: builder => {
        builder
        .addCase(authenticateUser.rejected, (state, action) => {
            state.isUserLockedOut = true;
        })
    }
})

export const authenticationActions = authenticationSlice.actions;
export const { setIsUserAuthenticated } = authenticationActions;
export default authenticationSlice.reducer;