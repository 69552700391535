import { useState } from 'react';
import { Popup } from 'reactjs-popup';
import { ImageButton } from '../../components/AppButtons';
import css from '../../components/Chart/Chart.module.scss';
import {
	HeaderCell, Table, TableBody,
	TableCell, TableHeader, TableRow
} from '../../components/Table';
import { withTheme } from '../../hoc/withTheme';
import { fetchBasicAnalytics } from '../../redux/aggregation-reducer/aggregation-slice';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { pageActions } from '../../redux/page-reducer/page-slice';
import { fetchAnalytics } from '../../redux/trades-reducer/trades-slice';
import { BasicAnalyticsPopupContent } from './BasicAnalyticsPopupContent';
import { CustomisablePopup } from './CustomisablePopup';
import css1 from './Trades.module.scss';
import Pagination from "react-js-pagination";
import css2 from './../../components/Table/Table.module.scss';

const tradeTableFields = [
	// { key: 'tradeId', label: 'Trade ID', commarise: false },
	{ key: 'tradeDate', label: 'Trade Date', commarise: false },
	{ key: 'tradeTime', label: 'Trade Time', commarise: false },
	{ key: 'valueDate', label: 'Value Date', commarise: false },
	{ key: 'type', label: 'Trade Type', commarise: false, sort: 'string' },
	// { key: 'buyCurrency', label: 'Buy CCY', commarise: false },
	// { key: 'sellCurrency', label: 'Sell CCY', commarise: false },
	{ key: 'currencyPair', label: 'Currency Pair', commarise: false, sort: 'string' },
	{ key: 'counterparty', label: 'Counterparty', commarise: false, sort: 'string' },
	{ key: 'bS', label: 'B/S', commarise: false, sort: 'string'  },
	{ key: 'amount', label: 'Amount', commarise: true, sort: 'number' },
	{ key: 'dealtRate', label: ' Dealt Rate', commarise: false, sort: 'number' },
	{ key: 'benchmarkRate', label: 'Benchmark Rate', commarise: false, sort: 'number' },
	{ key: 'fwdPoints', label: 'FWD Points', commarise: false, sort: 'number'},
	{ key: 'bpCost', label: 'BP Cost', commarise: false, sort: 'number' },
	{ key: 'usdCost', label: 'USD Cost', commarise: true, sort: 'number' },
];

const TradesTable = ({ filterRequests }) => {
	const dispatch = useAppDispatch();

	const tradesTable = useAppSelector((state) => state.aggregation.tradeTable);

	const totalNumberOfTrades = useAppSelector((state) => state.page.totalNumberOfTrades);
	const pageNumber = useAppSelector((state) => state.page.pageNumber);
	const numberOfRecordsPerPage = useAppSelector((state) => state.page.numberOfRecordsPerPage);
	const basicAggregatedAnalyticsRequestStatus = useAppSelector((state) => state.aggregation.basicAggregatedAnalyticsRequestStatus);

	// eslint-disable-next-line no-unused-vars
	const [sortedField, setSortedField] = useState(null);
	const [isExpanded, setIsExpanded] = useState(false);
	
	const [selectedRow, setSelectedRow] = useState(null);

	const isLoading = useAppSelector((state) => state.filters.filterPending);
	// eslint-disable-next-line no-unused-vars
	const tradeTableLoadingStatus = useAppSelector((state) => state.aggregation.tradeTableLoadingStatus);

	const handleExpand = (e) => {
		setIsExpanded(!isExpanded);
		if(e){
			e.stopPropagation();
		}
	};

	const handlePopupClose = (e) => {
		document.body.style.overflow = 'auto';
		handleExpand(e);
	}

	const fetchDecayChart = (rowData) => {
		dispatch(fetchAnalytics(rowData.tradeId));
	};

	const handlePageChange = (value) => {
		dispatch(pageActions.setPageNumber(value));
	}

	return (
		<>	
			<Popup open={isExpanded} onClose={handlePopupClose}>
				<CustomisablePopup title={"Advanced Trade Analytics"} loadingStatus={basicAggregatedAnalyticsRequestStatus}>
					<BasicAnalyticsPopupContent selectedRow={selectedRow}/>
				</CustomisablePopup>
			</Popup>

			
			<div className={css1.tradesTable}>
				<div className={ css2.table__titleHeader }>
					<div className={ css2.table__title }>
						{ tradesTable?.title || "" }
					</div>
					<div>
						<Pagination
							activePage={pageNumber}
							itemsCountPerPage={numberOfRecordsPerPage}
							totalItemsCount={totalNumberOfTrades}
							pageRangeDisplayed={5}
							onChange={handlePageChange}
							innerClass={css.tablepagination}
							activeLinkClass={css.tablepagination__activeLink}
							itemClass={css.tablepagination__item}
							linkClass={css.tablepagination__link}
							nextPageText={'>'}
							prevPageText={'<'}
						/>
					</div>
				</div>
				<Table>			
					<TableHeader>
						<TableRow>
							{ tradeTableFields.map(item => (
								<HeaderCell className={css.table__headercell}key={ item.key } onClick={() => {setSortedField(item)}}>
									{ item.label }
								</HeaderCell>
							)) }
							<HeaderCell key="Expand"></HeaderCell>
						</TableRow>
					</TableHeader>
					<TableBody>
						{ (tradesTable?.data || []).map(({ isSelected, ...rowData }) => (
							<TableRow
								key={ rowData.tradeId }
								onClick={ () => {
									if (!isLoading) {
										dispatch(fetchBasicAnalytics({ ...rowData, isSelected}));
									}	
								}}
								isSelected={ isSelected }
							>
								{ tradeTableFields.map(item => (
									<TableCell key={ item.key } commarise={item.commarise}>{ rowData[item.key] }</TableCell>
								)) }
								<TableCell key="Expand" onClick={(e)=>{
									document.body.style.overflow = 'hidden';
									fetchDecayChart(rowData);
									setSelectedRow(rowData);
									handleExpand(e);}
									} 
									commarise={false}
									style={{pointerEvents: 'none'}}>
									<ImageButton className={ css.chart__fullScreen } style={{pointerEvents: 'all'}}/>	
								</TableCell>
							</TableRow>
						)) }
					</TableBody>
				</Table>
			</div>
		</>
	);
};

export default withTheme(TradesTable);
