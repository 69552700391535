import { APIResponseType, baseApiInstance } from './api';

interface GetSummaryTableResponseDataType extends APIResponseType {
    summaryTables: SummaryTablesType
    summaryCharts: SummaryChartsType
    filters: FilterType
}

export const tablesAPI = {
    getSummaryTable(filterRequests: Array<FilterRequestType>) {
        return baseApiInstance().post<GetSummaryTableResponseDataType>('summary', filterRequests)
            .then(res => res.data)
    }
};