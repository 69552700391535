import { APIResponseType, baseApiInstance } from './api';

interface GetTradesResponseDataType extends APIResponseType {
    tradeTable: TradeTableType
    filters: FilterType
    aggregation: AggregationSummaryType
}

interface GetSelectedTradeAggregationResponseType extends APIResponseType {
    newTradeIds: Array<number>
    aggregation: AggregationSummaryType
}

interface GetDecayChartResponseType extends APIResponseType {
    decayData: DecayChartType
    implementationShortfallData: Array<DecayChartType>
}

interface GetAggregatedDecayChartResponseType extends APIResponseType {
    decayData: Array<DecayChartType>
    implementationShortfallData: Array<DecayChartType>
    tradeData: Array<AggregatedTradeType>
}

export const tradesAPI = {
    getTrades(filterRequests: TradesPageRequestType) {
        return baseApiInstance().post<GetTradesResponseDataType>('trades', filterRequests)
            .then(res => res.data)
            .catch(err => console.log(err)) as Promise<GetTradesResponseDataType>;
    },
    getSelectedTradeAggregation(selectedTrades: SelectedTradeAggregationRequestType, selectAllSelected: boolean) {
        if (selectAllSelected) {
            return baseApiInstance().post<GetSelectedTradeAggregationResponseType>('all_trade_summary', selectedTrades)
                .then(res => res.data)
                .catch(err => console.log(err)) as Promise<GetSelectedTradeAggregationResponseType>;
        }
        else {
            return baseApiInstance().post<GetSelectedTradeAggregationResponseType>('trade_summary', selectedTrades)
                .then(res => res.data)
                .catch(err => console.log(err)) as Promise<GetSelectedTradeAggregationResponseType>;
        }
    },
    getDecayChart(dataId: number) {
        return baseApiInstance().get<GetDecayChartResponseType>(`decay_chart?dataid=${dataId}`)
        .then(res => res.data)
        .catch(err => console.log(err)) as Promise<GetDecayChartResponseType>;
    },
    postAggregatedTradeSummary(trades: AdvancedAnalyticsAggregationRequestDataType) {
        return baseApiInstance().post<GetAggregatedDecayChartResponseType>('advanced_analytics_aggregation', trades)
        .then(res => res.data)
        .catch(err => console.log(err)) as Promise<GetAggregatedDecayChartResponseType>;
    }
};