import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import cn from 'classnames';

import { withTheme } from '../../hoc/withTheme';
import { fetchEfficiencyCharts } from '../../redux/charts-reducer/charts-reducer';
import { selectEfficiencyCharts } from '../../redux/charts-reducer/charts-selector';

import Chart from '../../components/Chart/Chart';

import css from './EfficiencyMetrics.module.scss';

const EfficiencyCharts = ({ theme }) => {
	const dispatch = useDispatch();
	const [lineChartData, barChartData] = useSelector(selectEfficiencyCharts);

	useEffect(() => {
		dispatch(fetchEfficiencyCharts());
	}, [dispatch]);

	const handleChartClick = (chartData, title, isActive) => {

	};

	return (
		<div className={ cn(css.efficiencyCharts, css[theme]) }>
			<Chart
				title={ lineChartData?.title }
				data={ lineChartData?.chartData }
				onClick={ handleChartClick }
				type='line'
			/>
			<Chart
				title={ barChartData?.title }
				data={ barChartData?.chartData }
				type='bar'
			/>
		</div>
	);
};

export default withTheme(EfficiencyCharts);
