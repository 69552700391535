import cn from "classnames";
import React, { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import Chart from "../../components/Chart/Chart";
import { withTheme } from "../../hoc/withTheme";
import { withTitle } from "../../hoc/withTitle";
import { fetchSummary } from "../../redux/summary-reducer/summary-slice";
import css from "./Summary.module.scss";
import css1 from "./../../components/Chart/Chart.module.scss";
import SummaryTable from "./SummaryTable";
import { PopupSubBox } from "../Trades/PopupSubBox";
import { RequestStatus } from "../../redux/upload-reducer/upload-slice";
import { ScaleLoader } from "react-spinners";
import { summaryActions } from "../../redux/summary-reducer/summary-slice";

const Summary = (props) => {
  const { theme, pastFilterRequests, filterRequests } = props;
  const dispatch = useAppDispatch();
  const summaryTables = useAppSelector((state) => state.summary.summaryTables);
  const summaryCharts = useAppSelector((state) => state.summary.summaryCharts);
  const summaryLoadingStatus = useAppSelector(
    (state) => state.summary.summaryLoadingStatus
  );
  const reload = useAppSelector((state) => state.summary.reloadNecessary);

	useEffect(() => {
		if (filterRequests !== pastFilterRequests.current) {
			dispatch(fetchSummary(filterRequests));
			pastFilterRequests.current = filterRequests;
		}
	}, [filterRequests]);

  useEffect(() => {
    if (reload) {
      dispatch(fetchSummary(filterRequests));
      pastFilterRequests.current = filterRequests;
      dispatch(summaryActions.setReloadNecessary(false));
    }
  }, [reload]);

	return (
		<>	
			{ summaryLoadingStatus === RequestStatus.Loading &&
				(<ScaleLoader  loading={true} color={'white'} height={'150px'} width={'10px'} />)
			}
			{ summaryLoadingStatus === RequestStatus.Rejected && (
				<div className={ css.summary }>
					<p className={css.empty}>
						The NCFX TCA Hub service cannot be reached. Please contact support@newchangefx.com for help.
					</p>
				</div>
			)} 
			{ summaryLoadingStatus === RequestStatus.Accepted && (
				<div className={ css.summary }>
					{ summaryTables === undefined ? 
					(
						<p className={css.empty}>
							YOU HAVE NO TRADES STORED IN OUR DATABASES. PLEASE UPLOAD A TRADE FILE TO VIEW YOUR TCA SUMMARY.
						</p>
					) : (
					<div className={ css.summary__tables }>
						{
							(summaryTables || []).map(({ title, data }) => (
								<SummaryTable key={ title } title={ title } data={ data }/>
							)) 
						}
						
					</div>
					)}
					<div className={ cn(css.summary__charts, css[theme]) }>
					{	
							(summaryCharts || []).map((chart) => (
							<PopupSubBox title={ chart.title } className={css1.chart} cssPackage={css1} isExpandable>
								<Chart 
									type='horizontalBar' 
									currencySymbol={chart.currencySymbol}
									data={chart.data}
								/>
							</PopupSubBox>
							))
					}
					</div>
				</div>
			)}
		</>
	);
};

export default withTheme(withTitle(Summary));
