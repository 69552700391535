import axios from 'axios';
import downloadFile from 'js-file-download';
import { APIResponseType, baseApiInstance } from './api';

interface GetStateInfoResponseDataType extends APIResponseType {
    data: Array<StateInfoType>
}

interface GetJwtAuthentication extends APIResponseType {
    Token: string
}

export const appAPI = {
    getStateInfo() {
        return baseApiInstance().get<GetStateInfoResponseDataType>('state-info')
            .then(res => res.data)
            .catch(err => console.log(err)) as Promise<GetStateInfoResponseDataType>;
    }
};

export const jwtAPI = {
    fetchJwtAuthentication() {
        let email = process.env.REACT_APP_USER_EMAIL;
        let password = process.env.REACT_APP_USER_PASSWORD;
        let base64 = btoa(email + ":" + password);
        const baseURL = process.env.REACT_APP_BASE_API_URL;
        let apiInstance = axios.create({
                                            baseURL,
                                            headers: {
                                                'Authorization': 'Basic ' + base64,
                                            }
                                        });


        return apiInstance.post<GetJwtAuthentication>('user/authenticate')
            .then(res => res.data) as Promise<GetJwtAuthentication>;
    }
};

type ExportFileAPIArgumentsType = {
    page: string
    params: any
}

//temp func here
export const exportFileAPI = {
    download: ({ page, params }: ExportFileAPIArgumentsType) => baseApiInstance().get('downloadFile').then(res => {
        downloadFile(new Blob([res.data]), 'mycsv.csv');
    })
};