import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { appActions } from '../redux/app-reducer/app-reducer';

export const withTitle = Component => ({ title, ...restProps }) => {
	const dispatch = useDispatch();

	useEffect(() => {
		dispatch(appActions.setCurrentPageTitle(title));
	}, [dispatch, title]);

	return <Component { ...restProps } />;
};