import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { powerBIAPI } from '../../api/powerbi-api';

enum RequestStatus {
    Loading,
    Accepted,
    Rejected
}

interface PowerBIState {
    powerBI: PowerBiToken
    loadingStatus: RequestStatus
}

const initialState = {
    powerBI : {},
    loadingStatus: RequestStatus.Loading
} as PowerBIState

export const fetchPowerBi = createAsyncThunk('powerBI/fetchDecay', async (request: PowerBiTokenRequest, thunkApi) => {
    const response = await powerBIAPI.fetchPowerBiToken(request);
    return response;
});

const powerBISlice = createSlice({
  name: 'powerBI',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder
        .addCase(fetchPowerBi.fulfilled, (state, action) => {
            state.powerBI = action.payload
            state.loadingStatus = RequestStatus.Accepted;
        })
        .addCase(fetchPowerBi.pending, (state, action) => {
            state.loadingStatus = RequestStatus.Loading;
        })
        .addCase(fetchPowerBi.rejected, (state, action) => {
            state.loadingStatus = RequestStatus.Rejected;
        })
    }
})

export const powerBIActions = powerBISlice.actions;
export default powerBISlice.reducer;