import cn from 'classnames';
import { useState } from 'react';
import { ProgressBar } from 'react-bootstrap';
import { Popup } from 'reactjs-popup';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { fetchReportStatuses } from '../../redux/upload-reducer/upload-slice';
import { CustomisablePopup } from '../Trades/CustomisablePopup';
import DeletionConfirmationPopupContent from './DeletionConfirmationPopupContent';
import { PastFileSubmissionPopupContent } from './PastFileSubmissionPopupContent';
import css from './Upload.module.scss';

export const SubmittedReportStatusBar = () => {
    const dispatch = useAppDispatch();
    const [isPopupEnabled, setIsPopupEnabled] = useState(false);
    const [isConfirmationPopupEnabled, setIsConfirmationPopupEnabled] = useState(false);
    const submittedReportStatuses = useAppSelector((state) => state.upload.submittedReportStatuses);
    const reportStatusesRequestStatus = useAppSelector((state) => state.upload.reportStatusesRequestStatus);

    return (
        <>
            <Popup open={isPopupEnabled} closeOnDocumentClick={false} onOpen={() => {dispatch(fetchReportStatuses());}} onClose={() => {setIsPopupEnabled(false);}}>
                    <CustomisablePopup title={"Past File Uploads"} loadingStatus={reportStatusesRequestStatus} close={()=>{setIsPopupEnabled(false)}}>
                        <PastFileSubmissionPopupContent/>
                    </CustomisablePopup>
            </Popup>
            <Popup open={isConfirmationPopupEnabled} onClose={() => {setIsConfirmationPopupEnabled(false);}} closeOnDocumentClick={false}>
                    <CustomisablePopup title={"CONFIRM DELETION OF ALL FILE UPLOADS"} >
                        <DeletionConfirmationPopupContent setOpen={setIsConfirmationPopupEnabled}/>
                    </CustomisablePopup>
            </Popup>
            <div className={cn(css.upload__progress__window, css.upload__table__div)}>
                    <div className={css.upload__progress__title_bar}>
                        <p className={css.upload__progress__title}>{ submittedReportStatuses.length > 0 ? ("Uploads In Progress") : ("No Uploads In Progress")}</p>
                        <div style={{display: 'flex', justifyContent: 'space-between'}}>
                            <button className={css.upload__progress__button} 
                                onClick={()=> {setIsPopupEnabled(true)}}>
                                VIEW PREVIOUS FILE UPLOADS
                            </button>
                            <button className={css.upload__progress__red_button} 
                                onClick={()=> {setIsConfirmationPopupEnabled(true)}}>
                                DELETE ALL FILE UPLOADS
                            </button>
                        </div>
                    </div>
                    {submittedReportStatuses.map((reportStatus, index) => {
                        if (reportStatus.status === "Processing" || reportStatus.status === "Submitted") {
                            return (
                            <div className={css.upload__progress__box}>
                                <p className={css.upload__progress__subtitle}>{reportStatus.fileName}</p>
                                <div >
                                    <ProgressBar className={css.upload__progress__progress_bar} striped animated now={reportStatus.percentageCompletion} label={`${reportStatus.percentageCompletion}%`}/>
                                </div>
                            </div>)
                        }
                        return null;
                    })}
            </div>
        </>            
    )
}