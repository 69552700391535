import { ReactSVG } from 'react-svg';
import css from './AppSelect.module.scss';
import cn from 'classnames';
import removeImg from '../../assets/images/svg/remove.svg';

import { filterActions } from '../../redux/filter-reducer/filter-slice';
import { useAppDispatch } from '../../redux/hooks';

export const FilterHeader = ({title, name, setReset, setChanged, changed}) => {
    // if (name=== 'counterparty') {
    //     console.log(changed);
    // }
    
    const dispatch = useAppDispatch();

    const onRemoveClick = () => {
        setChanged(false);
        dispatch(filterActions.removeFilterRequest(name));
        setReset(true);
    }

    return (
        <div style={{display: 'flex', justifyContent: 'flex-end', position: 'relative'}}>
            <p className={ cn(css.selectTitle, 'filter-item__title') }>{ title }</p>
            <div className={cn(css.remove,{ [css.visible] : changed})}>
                <ReactSVG src={ removeImg } onClick={onRemoveClick}/>
            </div>
        </div>
    )
}