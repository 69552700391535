import Chart from "../../components/Chart/Chart";
import css from "./Popup.module.scss";
import { Scrollbars } from "react-custom-scrollbars-2";
import { ScaleLoader } from "react-spinners";
import AppSelectTradesPage from "../../components/AppSelect/AppSelectTradesPage";

export const PopupDisplay = (props) => {
  const {
    trades,
    decay,
    implementationShortfall,
    loading,
    handleSelectChange,
  } = props;

  const isSpot = true;

  const getRelevantData = (dependentVariable) => {
    let relevantData = [];

    var i;
    for (i = 0; i < trades.length; i++) {
      let relevantDatapoint = {
        x: trades[i].title,
        y: trades[i].data[dependentVariable],
      };
      relevantData.push(relevantDatapoint);
    }
    relevantData.sort((a, b) => {
      return b.y - a.y;
    });

    return relevantData;
  };

  const data = [
    { title: "Counterparty", value: "Counterparty" },
    { title: "Currency Pair", value: "CurrencyPair" },
    { title: "Buy Currency", value: "BuyCcy" },
    { title: "Sell Currency", value: "SellCcy" },
    { title: "Customer Side Allocation", value: "CUSTSideAllocation" },
  ];

  return (
    <>
      {trades && (
        <div className={css.popup}>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <h1 className={css.maintitle}>Advanced Trade Analytics</h1>
            <AppSelectTradesPage
              key={"Dependent Variable"}
              title={"Dependent Variable"}
              options={data}
              handleSelect={handleSelectChange}
              name={"Dependent Variable"}
            />
          </div>
          {loading ? (
            <>
              <div className={css.scale_loader_box}>
                <ScaleLoader
                  loading={true}
                  color={"white"}
                  height={"150px"}
                  width={"10px"}
                />
              </div>
            </>
          ) : (
            <Scrollbars
              className={css.flexbox}
              renderThumbVertical={(props) => (
                <div {...props} className={css.popup__thumbvertical} />
              )}
            >
              {isSpot && (
                <Chart
                  title={"TOTAL USD Cost"}
                  data={getRelevantData("UsdCost")}
                  type="horizontalBar"
                  expandable={true}
                  popup={true}
                  currencySymbol={"$"}
                />
              )}
              {isSpot && (
                <Chart
                  title={"Total USD Amount"}
                  data={getRelevantData("UsdAmount")}
                  type="horizontalBar"
                  expandable={true}
                  popup={true}
                  currencySymbol={"$"}
                />
              )}
              {decay && decay.length > 0 ? (
                <Chart
                  title={"Decay"}
                  data={decay}
                  // onClick={ handleChartClick }
                  type="multiLine"
                  expandable={false}
                  fullSize={true}
                  popup={true}
                  currencySymbol={"$"}
                />
              ) : (
                <></>
              )}
              {isSpot && (
                <Chart
                  title={"Temporary Price Impact"}
                  data={getRelevantData("TemporaryPriceImpact")}
                  type="horizontalBar"
                  expandable={true}
                  popup={true}
                  currencySymbol={"$"}
                />
              )}
              {isSpot && (
                <Chart
                  title={"Permanent Price Impact"}
                  data={getRelevantData("PermanentPriceImpact")}
                  type="horizontalBar"
                  expandable={true}
                  popup={true}
                  currencySymbol={"$"}
                />
              )}
              {isSpot && (
                <Chart
                  title={"P&L @ 30 Seconds"}
                  data={getRelevantData("PAndLAtThirtySeconds")}
                  type="horizontalBar"
                  expandable={true}
                  popup={true}
                  currencySymbol={"$"}
                />
              )}
              {isSpot && (
                <Chart
                  title={"Unit Cost of Volatility"}
                  data={getRelevantData("UnitCostOfVolatility")}
                  type="horizontalBar"
                  expandable={true}
                  popup={true}
                  currencySymbol={""}
                />
              )}
              {implementationShortfall && (
                <Chart
                  title={"Implementation Shortfall"}
                  data={implementationShortfall}
                  type="bar"
                  expandable={false}
                  fullSize={true}
                  popup={true}
                  currencySymbol={"$"}
                />
              )}
            </Scrollbars>
          )}
        </div>
      )}
    </>
  );
};
