import Papa from "papaparse";
import React, { useCallback } from "react";
// Import the useDropzone hooks from react-dropzone
import { useDropzone } from "react-dropzone";
import { useAppDispatch } from "../../redux/hooks";
import { uploadActions } from "../../redux/upload-reducer/upload-slice";
// import cn from 'classnames';
import css from './Upload.module.scss';


const Dropzone = ({setContentsTable}) => {
    
    const dispatch = useAppDispatch();

    const removeCarriageReturnAtEndOfString = (csv) => {
      const endOfString = csv.slice(-2);
      //if binaryStr ends in /r/n or /r or /n remove it
      if (endOfString === '\r\n') {
          return csv.slice(0,-2);  
      } 
      if (endOfString[1] === '\r' || endOfString[1] === '\n') {
          return csv.slice(0,-1);
      }
      return csv
    }

    const onDrop = useCallback(acceptedFiles => {
        acceptedFiles.forEach((file) => {
            const reader = new FileReader()
            reader.readAsText(file);
            reader.onabort = () => console.log('file reading was aborted')
            reader.onerror = () => console.log('file reading has failed')
            reader.onload = () => {
            // Do whatever you want with the file contents    
              const binaryStr = removeCarriageReturnAtEndOfString(reader.result);

              const csv = Papa.parse(binaryStr, {header: true});
              
              // let isValid = checkValidityOfFile(csv);
              let isValid = true;
              csv.isValid = isValid;
        
              if (isValid) {
                dispatch(uploadActions.setFileNamesDropped(acceptedFiles[0].name));
                dispatch(uploadActions.setFileContentsDropped(binaryStr));
                csv.data = csv.data.slice(0,10);
                setContentsTable(previousVersion => [...previousVersion, csv]);
              }
              else {
                // setInvalidPopupEnabled(true);
              }
            }
          })},[dispatch, setContentsTable]);

    // Initializing useDropzone hooks with options
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: '.csv'
  });

  return (
    <div className={css.upload__dropzone} {...getRootProps()}>
      <input data-testId="upload" {...getInputProps()} />
      <div>
        {isDragActive ? (
          <p className={css.upload__dropzone__explanation}>Release to drop the files here.</p>
        ) : (
            <>
            <p className={css.upload__dropzone__explanation}>
            <span style={{fontWeight: 800}}>DROPZONE:</span> Drag and drop your files here, or click here to select files using file explorer.
            </p>
            </>
        )}
      </div>
    </div>
  );
};

export default Dropzone;