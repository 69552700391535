import cn from 'classnames';
import { models } from 'powerbi-client';
import { PowerBIEmbed } from "powerbi-client-react";
import { useEffect, useState } from "react";
import { withTitle } from "../../hoc/withTitle";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { fetchPowerBi } from "../../redux/powerbi-reducer/powerbi-slice";
import css1 from './PowerBi.module.scss';


const PowerBi = () => {
    const dispatch = useAppDispatch();
    
    const [fullScreen, setFullScreen] = useState(false);
    const [css, setCss] = useState("report-style-class")

    const powerbi = useAppSelector((state) => state.powerbi.powerBI);
    const jwt = useAppSelector((state) => state.authentication.jwt);
    const userId = useAppSelector((state) => state.authentication.userId);

    const jwtStored = useAppSelector((state) => state.authentication.jwtStored);

    useEffect(() => {
        if (jwtStored) {
            const request = {
                DatasetId: "747f235b-ce7a-4c94-916a-be67f9de67bc",
                ReportId: "cc47274f-f3c0-4ad1-80d6-fc1089cb0b83",
                UserToken: jwt,
                UserId: userId
            }
            
            dispatch(fetchPowerBi(request));
        }
    }, [dispatch, jwt, jwtStored, userId])

    useEffect(() => {
        var cssStyle = fullScreen? "report-style-class-full":"report-style-class";
        setCss(cssStyle);
    }, [fullScreen])

    const fullScreenButtonPress = () => {
        setFullScreen(!fullScreen);
    }

    return (
        <div className={cn({[css1.powerbi__fullscreen]: fullScreen}, css1.powerbi)}>
            <div className={css1.menu}>
                <button onClick={fullScreenButtonPress}>{fullScreen?"EXIT FULLSCREEN MODE":"ENTER FULLSCREEN MODE"}</button>
            </div>
            <PowerBIEmbed
                embedConfig = {{
                    type: 'report',   // Supported types: report, dashboard, tile, visual and qna
                    id: powerbi.ReportId,
                    embedUrl: powerbi.EmbedUrl,
                    accessToken: powerbi.EmbedToken,
                    tokenType: models.TokenType.Embed,
                    settings: {
                        panes: {
                            filters: {
                                visible: true
                            },
                            pageNavigation: {
                                visible: true
                            }
                        },
                    }
                }}
 
                // Define event handlers
                eventHandlers = {
                    new Map([
                        ['loaded', function () { console.log('Report loaded'); }],
                        ['rendered', function () { console.log('Report rendered'); }],
                        ['error', function (event) { console.log(event.detail); }]
                    ])
                }
                // Add CSS classes to the div element
                cssClassName = {css}
            
                // Get reference to the embedded object
                getEmbeddedComponent = { (embeddedReport) => {
                    window.report = embeddedReport;
                }}
            />
        </div>
    )
}

export default withTitle(PowerBi);