
import { ScaleLoader } from 'react-spinners';
import { withTitle } from '../../hoc/withTitle';
import css from './Authenticate.module.scss';

const Authenticate = ({lockout}) => {
    
	return (
        <>
        { lockout? (
            <>
                <p className={css.authenticate__explanation}>AUTHENTICATION FAILED</p>
                <p className={css.authenticate__explanation}>Please login via the <a className={css.authenticate__explanation__link} href={"https://portal.newchangefx.com"}>NewChangeFX Client Portal</a></p>
            </>
            ) : (
        <div className={css.authenticate__spinner}>
            <ScaleLoader loading={true} color={'white'} height={'150px'} width={'10px'} />
            <p className={css.authenticate__explanation}>AUTHENTICATING...</p>
        </div>)
        }
        </>
    );
};

export default withTitle(Authenticate);