import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { APIResponseType } from '../../api/api';
import { showErrorNotification, showSuccessNotification } from '../../components/Notifications/Notifications';
import { isFileUploadValid } from '../../routes/Upload/FileUploadValidator';
import { uploadAPI } from './../../api/upload-api';

export enum RequestStatus {
    Loading,
    Accepted,
    Rejected
}

interface UploadState {
    submittedReportStatuses: Array<SubmittedReportStatus>
    
    invalidFilePopupEnabled: boolean
    invalidFilePopupMessage: Array<string>

    reportStatuses: Array<ReportStatus>
    reportStatusesRequestStatus: RequestStatus

    fileNamesDropped: Array<String>
    fileContentsDropped: Array<String>
}

const initialState = {
    submittedReportStatuses: [] ,
    invalidFilePopupEnabled: false,
    invalidFilePopupMessage: [],
    reportStatuses: [],
    reportStatusesRequestStatus: RequestStatus.Loading,
    fileNamesDropped: [],
    fileContentsDropped: []
} as UploadState

export const fetchUpload = createAsyncThunk('upload/fetchUpload', async(request: PostUploadRequestDataType, thunkApi) => {
    try {
        const fileValidation = isFileUploadValid(request);
        if (fileValidation.passedValidation) {
            const response = await uploadAPI.postUpload(request);
            if (response.statusCode !== 200) {

                thunkApi.dispatch(uploadActions.setInvalidFilePopupEnabled(true));
                response.messages.forEach((message) => {
                    thunkApi.dispatch(uploadActions.setInvalidFilePopupMessage(request.filename + " invalid: " + message));
                })

                return thunkApi.rejectWithValue(fileValidation.message);
            }
            
            return response;
        }
        else {
            thunkApi.dispatch(uploadActions.setInvalidFilePopupEnabled(true));
            fileValidation.message.forEach((message) => {
                thunkApi.dispatch(uploadActions.setInvalidFilePopupMessage(request.filename + " invalid: " + message));
            })
            
            
        }
    }
    catch (error: any) {
        let errorResponse = error as APIResponseType;
        if (errorResponse?.response?.data?.error?.message) {
            thunkApi.dispatch(uploadActions.setInvalidFilePopupMessage(request.filename + " error : " + errorResponse.response.data.error.message));
        }
        else {
            thunkApi.dispatch(uploadActions.setInvalidFilePopupMessage(request.filename + " could not send: Lost connection to NCFX servers"));
        }
        thunkApi.dispatch(uploadActions.setInvalidFilePopupEnabled(true));
        throw error;
    }
    
});

export const deleteAllTrades = createAsyncThunk('upload/deleteAllTrades', async() => {
    
    const response = await uploadAPI.postDeleteAllTrades();
    return response;
});

export const deleteTradeFile = createAsyncThunk('upload/deleteTradeFile', async(fileId: DeleteFileRequest, {dispatch}) => {
    
    const response = await uploadAPI.postDeleteTradeFile(fileId);

    return response;
});

export const fetchSubmittedReportStatuses = createAsyncThunk('upload/fetchSubmittedReportStatuses', async(request: PostUploadRequestDataType) => {
    
    const response = await uploadAPI.getSubmittedReportStatuses();
    return response;
});

export const fetchReportStatuses = createAsyncThunk('upload/fetchReportStatuses', async(request: PostUploadRequestDataType) => {
    
    const response = await uploadAPI.getReportStatuses();
    return response;
});

// export const deleteAllFiles = createAsyncThunk('upload/deleteAllFiles', async() => {
//     const response = await uploadAPI.postDeleteAllTrades();
//     return response
// });

const uploadSlice = createSlice({
  name: 'upload',
  initialState,
  reducers: {
        setFileNamesDropped(state, action: PayloadAction<String>) {
            state.fileNamesDropped.push(action.payload);
        },
        setFileContentsDropped(state, action: PayloadAction<String>) {
            state.fileContentsDropped.push(action.payload);
        },
        resetFileNamesDropped(state) {
            state.fileNamesDropped = [];
        },
        resetFileContentsDropped(state) {
            state.fileContentsDropped = [];
        },
        setInvalidFilePopupEnabled(state, action: PayloadAction<boolean>) {
            state.invalidFilePopupEnabled = action.payload;
        },
        setInvalidFilePopupMessage(state, action: PayloadAction<string>) {
            state.invalidFilePopupMessage.push(action.payload);
        },
        resetInvalidFilePopupMessage(state) {
            state.invalidFilePopupMessage = [];
        }
  },
  extraReducers: builder => {
    builder
    .addCase(fetchUpload.fulfilled, (state, action) => {
        showSuccessNotification("Your file has been received successfully by NCFX servers.");
    })
    .addCase(fetchSubmittedReportStatuses.fulfilled, (state, action) => {
        state.submittedReportStatuses = action.payload.data;
    })
    .addCase(fetchReportStatuses.fulfilled, (state, action) => {
        state.reportStatuses = action.payload.data;
        state.reportStatusesRequestStatus = RequestStatus.Accepted;
    })
    .addCase(fetchReportStatuses.pending, (state, action) => {
        state.reportStatusesRequestStatus = RequestStatus.Loading;
    })
    .addCase(deleteAllTrades.fulfilled, (state, action) => {
        showSuccessNotification("All files have been deleted successfully.")
    })
    .addCase(deleteAllTrades.rejected, (state, action) => {
        showErrorNotification("Deletion of all files was not successful, please try again later or contact support@newchangefx.com.\r\n" + action.error.message);
    })
    .addCase(deleteTradeFile.fulfilled, (state, action) => {
        showSuccessNotification("File has been deleted successfully.")
    })
    .addCase(deleteTradeFile.rejected, (state, action) => {
        showErrorNotification("File deletion was not successful, please try again later or contact support@newchangefx.com.\r\n" + action.error.message);
    })
  }
})

export const uploadActions = uploadSlice.actions;
export default uploadSlice.reducer;