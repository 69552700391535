export const preventDefault = (e) => {
  e.preventDefault();
  return false;
};

export const isEmpty = (object) => {
  return Object.keys(object).length === 0;
};

export const disableScroll = (level) => {
  if (level === "soft") {
    window.addEventListener("wheel", preventDefault, { passive: false });
    window.addEventListener("scroll", preventDefault, { passive: false });
  } else if ("super-soft") {
    document.body.classList.add("no-scroll");
    document.documentElement.classList.add("no-scroll");
  } else {
    const x = window.scrollX;
    const y = window.scrollY;
    window.onscroll = function () {
      window.scrollTo(x, y);
    };
    window.addEventListener("wheel", preventDefault, { passive: false });
    window.addEventListener("scroll", preventDefault, { passive: false });
    window.addEventListener("touchmove", preventDefault, { passive: false });
    window.addEventListener("touchstart", preventDefault, { passive: false });
  }
  document.body.classList.add("no-scroll");
  document.documentElement.classList.add("no-scroll");
};

export const enableScroll = (level) => {
  if (level === "soft") {
    window.removeEventListener("wheel", preventDefault, { passive: false });
    window.removeEventListener("scroll", preventDefault, { passive: false });
  } else if ("super-soft") {
    document.body.classList.remove("no-scroll");
    document.documentElement.classList.remove("no-scroll");
  } else {
    window.onscroll = function () {};
    window.removeEventListener("wheel", preventDefault, { passive: false });
    window.removeEventListener("scroll", preventDefault, { passive: false });
    window.removeEventListener("touchmove", preventDefault, { passive: false });
    window.removeEventListener("touchstart", preventDefault, {
      passive: false,
    });
  }
  document.body.classList.remove("no-scroll");
  document.documentElement.classList.remove("no-scroll");
};

export const goTop = () => {
  window.scrollTo({
    top: 0,
    behavior: "smooth",
  });
};

export const numberWithCommas = (x) => {
  if (!isNaN(x)) {
    let commarizedNumber = x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    return commarizedNumber;
  }
  return 0;
};

export function commarize(min) {
  min = min || 1e3;
  // Alter numbers larger than 1k
  if (this >= min) {
    var units = ["K", "M", "B", "T"];

    var order = Math.floor(Math.log(this) / Math.log(1000));

    var unitname = units[order - 1];
    var num = this / 1000 ** order;
    var decimalNumber = num.toFixed(1);
    // output number remainder + unitname
    return decimalNumber + unitname;
  }

  // return formatted original number
  return this.toLocaleString();
}

export const hexToRgbA = (hex, opacity = 1) => {
  let c;
  if (/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)) {
    c = hex.substring(1).split("");
    if (c.length === 3) {
      c = [c[0], c[0], c[1], c[1], c[2], c[2]];
    }
    c = "0x" + c.join("");
    return (
      "rgba(" +
      [(c >> 16) & 255, (c >> 8) & 255, c & 255].join(",") +
      "," +
      opacity +
      ")"
    );
  }
  console.log(hex);
  throw new Error("Bad Hex");
};

function hslToHex(h, s, l) {
  l /= 100;
  const a = (s * Math.min(l, 1 - l)) / 100;
  const f = (n) => {
    const k = (n + h / 30) % 12;
    const color = l - a * Math.max(Math.min(k - 3, 9 - k, 1), -1);
    return Math.round(255 * color)
      .toString(16)
      .padStart(2, "0"); // convert to Hex and prefix "0" if needed
  };
  return `#${f(0)}${f(8)}${f(4)}`;
}

export const getColors = (index, total) => {
  let divisions = 360 / total;
  let hue = index * divisions;

  let hex = hslToHex(hue, 100, 70);

  if (total === 1) {
    return "#f95986";
  }
  return hex;
};

export const getChartGradients = (ctx, index, total) => {
  const gradient = ctx.createLinearGradient(0, 0, 0, 300);
  // const idx = themeColorsArr.length - 1 >= index ? index - themeColorsArr.length : index;
  const lineColor = getColors(index, total);

  gradient.addColorStop(0, hexToRgbA(lineColor, 0.8));
  gradient.addColorStop(1, "transparent");

  return { lineColor, gradient };
};

export const getHorizontalChartGradients = (ctx, data, isExpanded) => {
  const isValuePositive = data.length
    ? data.map((datapoint) => datapoint.y >= 0)
    : [];
  const opacity1 = 0.1;
  const opacity2 = 0.75;
  const lineColor = [];
  const gradients = [];
  const gradientLength = isExpanded ? 1000 : 500;
  for (let i = 0; i < data.length; i++) {
    const color = getColors(i, data.length);
    lineColor.push(color);
    const gradient = ctx.createLinearGradient(0, 0, gradientLength, 0);
    gradient.addColorStop(0, hexToRgbA(color, opacity1));
    gradient.addColorStop(1, hexToRgbA(color, opacity2));

    const reverseGradient = ctx.createLinearGradient(0, 0, gradientLength, 0);
    reverseGradient.addColorStop(0, hexToRgbA(color, opacity2));
    reverseGradient.addColorStop(1, hexToRgbA(color, opacity1));

    if (isValuePositive[i]) {
      gradients.push(gradient);
    } else {
      gradients.push(reverseGradient);
    }
  }
  return { lineColor, gradients };
};
