import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface PageState {
    pageNumber: number
    numberOfRecordsPerPage: number
    totalNumberOfTrades: number
}

const initialState = {
    pageNumber: 1,
    numberOfRecordsPerPage: 50,
    totalNumberOfTrades: 0
} as PageState

const pageSlice = createSlice({
  name: 'page',
  initialState,
  reducers: {
      setPageNumber(state, action: PayloadAction<number>) {
          state.pageNumber = action.payload;
      },
      setTotalNumberOfTrades(state, action: PayloadAction<number>) {
          state.totalNumberOfTrades = action.payload;
      }
  }
})

export const pageActions = pageSlice.actions;
export default pageSlice.reducer;