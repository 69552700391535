import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import cn from "classnames";
import { Scrollbars } from "react-custom-scrollbars-2";
import moment from "moment";
import { withTheme } from "../../../hoc/withTheme";

import AppSelect from "../../AppSelect/AppSelect";
import AppDatepicker from "../../AppDatepicker/AppDatepicker";
import AppTimepicker from "../../AppTimepicker/AppTimepicker";
import AppInputRange from "../../AppInputRange/AppInputRange";
import AppCustomRange from "../../AppCustomRange/AppCustomRange";
import { filterActions } from "../../../redux/filter-reducer/filter-slice";
import css from "./MainFilter.module.scss";

const MainFilter = ({ isFilterActive, theme }) => {
  // const [isReset, setIsReset] = useState(false);
  const dispatch = useAppDispatch();

  const [rangeChangeOccurring, setRangeChangeOccurring] = useState(false);
  const [countdownTimer, setCountdownTimer] = useState(0);
  const [rangeFilterChange, setRangeFilterChange] = useState([]);

  const filterRequests = useAppSelector(
    (state) => state.filters.filterRequests
  );

  useEffect(() => {
    setRangeChangeOccurring(false);
    if (countdownTimer > 0) {
      countdownTimer > 0 &&
        setTimeout(() => {
          setCountdownTimer(countdownTimer - 100);
        }, 1);
    }
  }, [countdownTimer, rangeChangeOccurring]);

  useEffect(() => {
    if (countdownTimer === 0 && rangeFilterChange) {
      addFilterToRequest(rangeFilterChange);
    }
  }, [countdownTimer, rangeFilterChange]);

  const addFilterToRequest = (filterRequest) => {
    let oldRequests;

    if (
      filterRequests.filter((e) => e.name === filterRequest.name).length > 0
    ) {
      oldRequests = filterRequests.filter((e) => e.name !== filterRequest.name);
    } else {
      oldRequests = filterRequests;
    }

    let newFilterRequests;
    if (!Array.isArray(filterRequest)) {
      newFilterRequests = [...oldRequests, filterRequest];
    } else {
      newFilterRequests = oldRequests;
    }
    setRangeFilterChange(false);
    dispatch(filterActions.setFilterRequests(newFilterRequests));
  };

  const filters = useAppSelector((state) => state.filters.filters);

  const handleCalendarChange = ({ name, dates }) => {
    if (dates) {
      dates = dates ? dates : new Date();
      const dateFormat = "yyyy-MM-DD";
      const dateString =
        moment(dates[0]).format(dateFormat) +
        "," +
        moment(dates[1]).format(dateFormat);
      addFilterToRequest({ name: name, value: dateString });
    }
  };

  const handleRangeChange = ({ name, value }) => {
    setCountdownTimer(1500);
    setRangeChangeOccurring(true);
    const rangeString = value.min + "," + value.max;
    setRangeFilterChange({ name: name, value: rangeString });
    console.log("Range change started " + value.max);
  };

  const handleTimeRangeChange = ({ name, dates }) => {
    if (dates) {
      setCountdownTimer(1500);
      setRangeChangeOccurring(true);
      const rangeString = dates[0] + "," + dates[1];
      setRangeFilterChange({ name: name, value: rangeString });
      console.log("Time Range change started " + dates[1]);
    }
  };

  const handleSelectChange = ({ name, option }) => {
    if (option) {
      addFilterToRequest({ name: name, value: option.label });
    }
  };

  // const handleReset = () => {
  // 	setIsReset(true);
  // 	dispatch(filterActions.setFilterRequests([]));
  // 	setCountdownTimer(0);
  // 	setTimeout(() => {
  // 		setIsReset(false);
  // 	}, 1000);
  // };

  return (
    <section
      className={cn(css.filter, css[theme], { [css.active]: isFilterActive })}
    >
      <Scrollbars
        renderThumbVertical={(props) => (
          <div {...props} className="thumb-vertical" />
        )}
      >
        {(filters || []).map(({ type, title, data, min, max, name }) => {
          const minDate = moment(min, "DD/MM/YYYY").toDate();
          const maxDate = moment(max, "DD/MM/YYYY").toDate();
          const minTime = moment(min, "HH:mm:ss").toDate();
          const maxTime = moment(max, "HH:mm:ss").toDate();
          switch (type) {
            case "calendar":
              return (
                <AppDatepicker
                  key={name}
                  title={title}
                  onChange={handleCalendarChange}
                  name={name}
                  isReset={false}
                  initialValue={[minDate, maxDate]}
                />
              );
            case "dropdown":
              return (
                <AppSelect
                  key={name}
                  title={title}
                  options={data}
                  handleSelect={handleSelectChange}
                  name={name}
                  isReset={false}
                />
              );
            case "range":
              return (
                <AppInputRange
                  key={name}
                  name={name}
                  title={title}
                  min={min}
                  max={max}
                  onChange={handleRangeChange}
                  isReset={false}
                />
              );
            case "timerange":
              return (
                <AppTimepicker
                  key={name}
                  name={name}
                  title={title}
                  initialValue={[minTime, maxTime]}
                  onChange={handleTimeRangeChange}
                  isReset={false}
                />
              );
            case "customrange":
              return (
                <AppCustomRange
                  key={name}
                  name={name}
                  title={title}
                  initialValue={{ min, max }}
                  onChange={handleRangeChange}
                  isReset={false}
                />
              );
            default:
              return "No such kind of Filter Type";
          }
        })}
        {/* <button className={ css.filterBtn } onClick={ handleReset }>
					RESET FILTERS
				</button> */}
      </Scrollbars>
    </section>
  );
};

export default withTheme(MainFilter);
