import { useEffect, useState } from 'react';
import { withTitle } from '../../hoc/withTitle';
import { aggregationActions, fetchTrades, RequestStatus } from '../../redux/aggregation-reducer/aggregation-slice';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import css from './Trades.module.scss';
import TradesSummary from './TradesSummary';
import TradesTable from './TradesTable';
import { ScaleLoader } from 'react-spinners';

const Trades = props => {
	const dispatch = useAppDispatch();
	const {pastFilterRequests, filterRequests} = props;
	const [initialRender, setInitialRender] = useState(true);
	const pageNumber = useAppSelector((state) => state.page.pageNumber);
	const reload = useAppSelector((state) => state.aggregation.reloadNecessary);
	const loadingStatus = useAppSelector((state) => state.aggregation.tradeTableLoadingStatus);

	useEffect(() => {
		if (filterRequests !== pastFilterRequests.current) {
			dispatch(fetchTrades(filterRequests));
			dispatch(aggregationActions.resetBasicAggregatedAnalytics())
			pastFilterRequests.current = filterRequests;
		}
	
	}, [filterRequests]);

	useEffect(() => {
		if (reload) {
			dispatch(fetchTrades(filterRequests));
			dispatch(aggregationActions.resetBasicAggregatedAnalytics())
			pastFilterRequests.current = filterRequests;
			dispatch(aggregationActions.setReloadNecessary(false));
		}
	
	}, [reload]);

	useEffect(() => {
		if (!initialRender) {
			dispatch(fetchTrades(filterRequests));
		}
		else {
			setInitialRender(false);
		}
	
	}, [pageNumber]);

	return (
		<div className={ css.trades }>
			{loadingStatus === RequestStatus.Loading && (
				<ScaleLoader  loading={true} color={'white'} height={'150px'} width={'10px'} />
			)}
			{loadingStatus === RequestStatus.Accepted && (
				<>
					<TradesSummary/>
					<TradesTable filterRequests={ filterRequests }/>
				</>
			)}
			{loadingStatus === RequestStatus.Rejected && (
				<p className={css.empty}>
					The NCFX TCA Hub service cannot be reached. Please contact support@newchangefx.com for help.
				</p>
			)}

			
		</div>

	);
};

export default withTitle(Trades);
