import React from 'react';

import { withTitle } from '../../hoc/withTitle';

const Settings = () => {
	return (
		<div>

		</div>
	);
};

export default withTitle(Settings);
