import { InferActionsTypes } from "../store";

const SET_PRELOADER = "app/SET_PRELOADER";
const SET_FILTER_ACTIVE = "app/SET_FILTER_ACTIVE";
const SET_CURRENT_PAGE_TITLE = "app/SET_CURRENT_PAGE_TITLE";
const SET_THEME = "app/SET_THEME";
const SET_ERRORS = "app/SET_ERRORS";
const REMOVE_ERROR = "app/REMOVE_ERROR";
const SET_SUCCESS_MESSAGES = "app/SET_SUCCESS_MESSAGES";
const REMOVE_SUCCESS_MESSAGES = "app/REMOVE_SUCCESS_MESSAGES";

const initialState = {
  showPreloader: false,
  isFilterActive: false,
  currentPageTitle: "TCA Summary",
  theme: "dark" as ThemeType,
  // errors: ['This is long text test message ... !', 'Some error message'] as Array<string>
  errors: [] as Array<string>,
  successMessages: [] as Array<string>,
};

type InitialStateType = typeof initialState;

const appReducer = (
  state = initialState,
  action: AppActionsTypes
): InitialStateType => {
  switch (action.type) {
    case SET_PRELOADER:
    case SET_FILTER_ACTIVE:
    case SET_CURRENT_PAGE_TITLE:
    case SET_THEME:
      return { ...state, ...action.payload };
    case SET_ERRORS:
      return {
        ...state,
        errors: [...state.errors, action.errors],
      };
    case REMOVE_ERROR:
      return {
        ...state,
        errors: state.errors.filter((err) => err !== action.error),
      };
    case SET_SUCCESS_MESSAGES:
      return {
        ...state,
        successMessages: [...state.successMessages, action.successMessages],
      };
    case REMOVE_SUCCESS_MESSAGES:
      return {
        ...state,
        successMessages: state.successMessages.filter(
          (err) => err !== action.successMessages
        ),
      };
    default:
      return state;
  }
};

export const appActions = {
  setPreloader: (showPreloader: boolean) =>
    ({ type: SET_PRELOADER, payload: { showPreloader } } as const),
  setIsFilterActive: (isFilterActive: boolean) =>
    ({ type: SET_FILTER_ACTIVE, payload: { isFilterActive } } as const),
  setCurrentPageTitle: (currentPageTitle: string) =>
    ({
      type: SET_CURRENT_PAGE_TITLE,
      payload: { currentPageTitle },
    } as const),
  setTheme: (theme: ThemeType) =>
    ({ type: SET_THEME, payload: { theme } } as const),
  setErrors: (errors: string) => ({ type: SET_ERRORS, errors } as const),
  removeError: (error: string) => ({ type: REMOVE_ERROR, error } as const),
  setSuccessMessages: (successMessages: string) =>
    ({ type: SET_SUCCESS_MESSAGES, successMessages: successMessages } as const),
  removeSuccessMessages: (successMessages: string) =>
    ({
      type: REMOVE_SUCCESS_MESSAGES,
      successMessages: successMessages,
    } as const),
};

export type AppActionsTypes = InferActionsTypes<typeof appActions>;
// type ThunkType = BaseThunkType<AppActionsTypes>

export default appReducer;
