import cn from 'classnames';
import { withTheme } from '../../hoc/withTheme';
import { useAppSelector } from '../../redux/hooks';
import css from './StateInfo.module.scss';


const IndicatorStatus = ({ theme }) => {
	const stateInfo = useAppSelector((state) => state.appStatus.stateInfo);

	return (
		<div className={ cn(css.stateInfo, css[theme]) }>
			{
				(stateInfo || []).map((item, idx) => <InfoCell key={ idx } { ...item } />)
			}
		</div>
	);
};

const InfoCell = ({ title, value, type = 'success' }) => (
	<div className={ cn(css.stateInfo__item, css[type]) }>
		<p className={ cn('text', css.stateInfo__title) }>{title}</p>
		<p className={ cn('text', css.stateInfo__status) }>{value}</p>
	</div>
);

export default withTheme(IndicatorStatus);
