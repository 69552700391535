// export const themeColorsArr = [
// 	'#f95986',
// 	'#ffffff',
// 	'#E86376',
// 	'#8ED971',
// 	'#60C1FD',
// 	'#39AEF5',
// 	'#039cfc',
// 	'#60FDC4',
// 	'#F9F9F9',
// 	'#7689AD',
// 	'#EFF0F2',
// 	'#C6D2EA'
// ];

export const themeColorsArr = [
'#f95986',
'#FF355E',
'#FF6037',
'#FFCC33',
'#FFFF66',
'#66FF66',
'#AAF0D1',
'#50BFE6',
'#FF6EFF',
// '#EE34D2',
'#FF00CC',
// '#FF00CC',
// '#FD5B78',
// '#FF9966',
// '#FF9933',
// '#CCFF00',

'#FF355E',
'#FF6037',
'#FFCC33',
'#FFFF66',
'#66FF66',
'#AAF0D1',
'#50BFE6',
'#FF6EFF',
'#EE34D2',
'#FF00CC',
'#FF00CC',
'#FD5B78',
'#FF9966',
'#FF9933',
'#CCFF00',

//REPEATS
'#50BFE6',
'#FF6EFF',
'#EE34D2',
'#FF00CC',
'#FF00CC',
'#FD5B78',
'#FF9966',
'#FF9933',
'#CCFF00',
]

export const mainFilters = [];