import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { mainFilters } from "../../utils/constants";

// enum RequestStatus {
//   Loading,
//   Accepted,
//   Rejected,
// }

interface FilterState {
  filters: FilterType;
  filterRequests: Array<FilterRequestType>;
  filterPending: boolean;
  pendingRequestList: Array<number>;
  filterReset: boolean;
  efficiencyActiveFilters: Array<string>;
}

const initialState = {
  filters: mainFilters,
  filterRequests: [],
  filterPending: false,
  pendingRequestList: [],
  filterReset: true,
  efficiencyActiveFilters: [],
} as FilterState;

const filterSlice = createSlice({
  name: "filter",
  initialState,
  reducers: {
    setFilterRequests(state, action: PayloadAction<Array<FilterRequestType>>) {
      state.filterRequests = action.payload;
    },
    removeFilterRequest(state, action: PayloadAction<string>) {
      state.filterRequests = state.filterRequests.filter(
        (request) => request.name === action.payload
      );
    },
    setPendingRequest(state, action: PayloadAction<number>) {
      if (!state.pendingRequestList.includes(action.payload)) {
        state.filterPending = true;
        state.pendingRequestList.push(action.payload);
      }
    },
    removePendingRequest(state, action: PayloadAction<number>) {
      if (state.pendingRequestList.includes(action.payload)) {
        if (state.pendingRequestList.length === 1) {
          state.filterPending = false;
          state.pendingRequestList = [];
        } else {
          state.pendingRequestList.filter(
            (requestId) => requestId === action.payload
          );
        }
      }
    },
    setFilters(state, action: PayloadAction<FilterType>) {
      state.filters = action.payload;
    },
  },
});

export const filterActions = filterSlice.actions;
export default filterSlice.reducer;
