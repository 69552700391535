import { useState, useEffect } from 'react';
import { defaults } from 'react-chartjs-2';

import MultiLineChart from '../Charts/LineChart/MultiLineChart';
import BarChart from '../Charts/BarChart/BarChart';
import HorizontalBarChart from '../Charts/BarChart/HorizontalBarChart';

defaults.global.animationSteps = 50;
defaults.global.tooltipTitleFontStyle = 'normal';
defaults.global.animationEasing = 'easeOutBounce';
defaults.global.responsive = true;
defaults.global.scaleFontSize = 16;
defaults.global.maintainAspectRatio = false;
defaults.global.bezierCurve = false;
defaults.global.legend.display = false; // remove label above

const Chart = ({ title, type = 'line', data, onClick, popup = false, currencySymbol}) => {
	const isActive = false // Placeholder for now
	// const [isActive, setIsActive] = useState(false);
	const [graphHeight, setGraphHeight] = useState(0);

	useEffect(() => {
		if (data.length <= 6) {
			setGraphHeight('250px');
		} else {
			let height = 30 + (30 * data.length);
			setGraphHeight(height + 'px')
		}
	}, [data])

	useEffect(() => {
		onClick && onClick(data, title, isActive);
	}, [isActive, onClick, data, title]);

	return (
		<>
		{ type === 'multiLine' ? <MultiLineChart data={ data } currencySymbol={currencySymbol}/> : null }
		{ type === 'bar' ? <BarChart data={ data } currencySymbol={currencySymbol}/> : null }
		{ type === 'horizontalBar' ? (<div style={{height:graphHeight}}>
			<HorizontalBarChart data={ data } title={ title } currencySymbol={currencySymbol} 
				/></div>)
			: null}
		</>
	)
};

export default Chart;
