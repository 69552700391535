import { useEffect, useState } from 'react';
import cn from 'classnames';
import { withTheme } from '../../hoc/withTheme';
import css from '../AppDatepicker/AppDatepicker.module.scss';
import css1 from '../AppSelect/AppSelect.module.scss';
import { FilterHeader } from '../AppSelect/FilterHeader';
const AppCustomRange = ({
						   title, name, isReset, theme, onChange, initialValue, ...otherProps
					   }) => {
	const isFocused = false;
	// const [isFocused, setIsFocused] = useState(false);

	const [isMinInvalid, setIsMinInvalid] = useState(false);
	const [isMaxInvalid, setIsMaxInvalid] = useState(false);

    const [minValue, setMinValue] = useState("");
    const [maxValue, setMaxValue] = useState("");

	const [reset, setReset] = useState(false);
	const [changed, setChanged] = useState(false);

	useEffect(()=>{
		let isMaxValueEmpty = maxValue === "" || maxValue === "-";
		let isMinValueEmpty = minValue === "" || minValue === "-";

		let max = isMaxValueEmpty? initialValue.max : maxValue;
		let min = isMinValueEmpty? initialValue.min : minValue;
		
		if (!isMinValueEmpty || !isMaxValueEmpty) {
			onChange({name, value: { min, max }});
		}
	// eslint-disable-next-line react-hooks/exhaustive-deps
	},[minValue,maxValue])


    const onMinChange = (event) => {
		setChanged(true);
		setIsMinInvalid(isInvalid(event.target.value));
        if (!isMinInvalid) {
			setMinValue(event.target.value);
		}
    }

	const onMaxChange = (event) => {
		setChanged(true);
		setIsMaxInvalid(isInvalid(event.target.value));
		if (!isMaxInvalid) {
        	setMaxValue(event.target.value);
		}
    }


	const isInvalid = (value) => {
		if (value.match(/^-?\d*\.?\d*$/)){
			if (value.match(/[a-zA-Z]/g)) {
				return true;
			}
			else {
				return false;
			}
		} else {
			return true;
		}
	}

	useEffect(() => {
		if (reset) {
			setReset(false);
			setMinValue("");
			setMaxValue("");
		}
	}, [reset]);

	return (
		// <div
		// 	className={ cn(css.datepicker, theme, css[theme], 'app-datepicker', 'filter-item', { [css.focus]: isFocused }) }>
		<div className={ cn(css1.select, theme, 'app-select', 'filter-item', { [css.focus]: isFocused }) }>
			<FilterHeader title={title} name={name} setReset={setReset} changed={changed} setChanged={setChanged}/>
            <div className={css.customRangeBox}>
				<input className={cn( { [css.customRangeInvalid]: isMinInvalid }, css.customRange)} placeholder={initialValue.min} value={minValue} onChange={onMinChange}/>
                <p style={{color: 'white'}}>TO</p>
                <input className={cn( { [css.customRangeInvalid]: isMaxInvalid }, css.customRange)} placeholder={initialValue.max} value={maxValue} onChange={onMaxChange} />
            </div>
		</div>
		
	);
};

export default withTheme(AppCustomRange);
