import React from 'react';
import cn from 'classnames';
import { ReactSVG } from 'react-svg';

import { withTheme } from '../../hoc/withTheme';

import fullScreenSVG from '../../assets/images/svg/full-screen.svg';

import css from './AppButtons.module.scss';

const ImageButton = ({ theme, className, svg = fullScreenSVG, svgClass, ...props }) => (
	<button
		data-testid="image-button"
		{ ...props }
		className={ cn(css.btn__fullScreen, className, css[theme]) }
	>
		<ReactSVG className={svgClass} src={ svg }/>
	</button>
);

export default withTheme(ImageButton);
