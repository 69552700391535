import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { tablesAPI } from '../../api/tables-api'
import { filterActions } from '../filter-reducer/filter-slice'


enum RequestStatus {
    Loading,
    Accepted,
    Rejected
}

interface SummaryState {
    reloadNecessary: boolean
    summaryTables: SummaryTablesType,
	summaryCharts: SummaryChartsType,
    summaryLoadingStatus: RequestStatus,
}

const initialState = {
    reloadNecessary: false,
    summaryTables: [],
	summaryCharts: [],
    summaryLoadingStatus: RequestStatus.Loading
} as SummaryState

export const fetchSummary = createAsyncThunk('aggregation/fetchTrades', async (filterRequests: Array<FilterRequestType>, thunkApi) => {
    try {

        thunkApi.dispatch(summaryActions.setSummaryLoadingStatus(RequestStatus.Loading));
		const { summaryTables, summaryCharts, filters } = await tablesAPI.getSummaryTable(filterRequests);

        thunkApi.dispatch(summaryActions.setSummaryTable(summaryTables));
        thunkApi.dispatch(summaryActions.setSummaryChart(summaryCharts));
        thunkApi.dispatch(filterActions.setFilters(filters));

	} catch (e) {
		console.log(e);
        return thunkApi.rejectWithValue("REJECTED");
	}
});


const summarySlice = createSlice({
  name: 'summary',
  initialState,
  reducers: {
      setSummaryTable(state, action: PayloadAction<SummaryTablesType>) {
            state.summaryLoadingStatus = RequestStatus.Accepted;          
            state.summaryTables = action.payload;
      },
      setSummaryChart(state, action: PayloadAction<SummaryChartsType>) {
          state.summaryCharts = action.payload;
      },
      setSummaryLoadingStatus(state, action: PayloadAction<RequestStatus>) {
          state.summaryLoadingStatus = action.payload;
      },
      setReloadNecessary(state, action: PayloadAction<boolean>) {
          state.reloadNecessary = action.payload;
      }
  },
  extraReducers: builder => {
      builder
        .addCase(fetchSummary.pending, (state, action) => {
            
        })
        .addCase(fetchSummary.rejected, (state, action) => {
            state.summaryLoadingStatus = RequestStatus.Rejected;
        })
        .addCase(fetchSummary.fulfilled, (state, action) => {
            state.summaryLoadingStatus = RequestStatus.Accepted;
        })

  }
})

export const summaryActions = summarySlice.actions;
export default summarySlice.reducer;