import React from 'react';
import cn from 'classnames';

import { withTheme } from '../../hoc/withTheme';

import css from './Table.module.scss';

const HeaderCell = ({ children, className, onClick,...props }) => (
	<th onClick={onClick}
		{ ...props }
		className={ cn(css.table__headerCell, className) }
	>
		{ children }
	</th>
);

export default withTheme(HeaderCell);