import React from 'react';
import cn from 'classnames';
import { withTheme } from '../../hoc/withTheme';

import css from './Table.module.scss';

const TableRow = ({ className, children, isSelected = false, ...props }) => (
	
	
	<tr data-testid="table-row"
		{ ...props }
		className={ cn(className, { [css.selected]: isSelected }, css.table__row) }
	>
		{ children }
	</tr>
);

export default withTheme(TableRow);