import css from './Popup.module.scss';
import { RequestStatus } from '../../redux/upload-reducer/upload-slice';
import { ScaleLoader } from 'react-spinners';
import cn from 'classnames';
import svg from '../../assets/images/svg/cross.svg';
import { ImageButton } from '../../components/AppButtons';
export const CustomisablePopup = ({title, children, loadingStatus, classname, close}) => {

  return(
      <div className={cn(css.popup, classname)}>
        <div className={css.titlebar}>
            <h1 className={css.maintitle}>{title}</h1>
            { close && (
                <ImageButton onClick={close} className={css.exit_button} svg={svg} svgClass={css.popup__cross} />
            )}
        </div>
     
            {loadingStatus=== RequestStatus.Loading?
            (<div className={css.scale_loader_box}><ScaleLoader loading={true} color={'white'} height={'150px'} width={'10px'} /></div>):
            (
                <div className={css.flexbox} >
                    {children}
                </div>
            )} 
      </div>
  )};