import css from './InvalidFilePopup.module.scss';

export const InvalidFilePopup = ({messages}) => {
    return (
        <div className={css.invalidFilePopup}>
            {
                messages.map(message => (
                    <p className={css.invalidFilePopup__title}>{message}</p>
                ))
            }
            
        </div>
    )
}