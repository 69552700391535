import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';

import reportWebVitals from './reportWebVitals';

import App from './App';

import './scss/index.scss';
import store from './redux/store';

require('dotenv').config({
	path: `.env.${ process.env.NODE_ENV }`
});

ReactDOM.render(
	<Provider store={ store }>
		<BrowserRouter>
			<React.StrictMode>
				<App/>
			</React.StrictMode>
		</BrowserRouter>
	</Provider>,
	document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
