import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { ScaleLoader } from 'react-spinners';
import { Popup } from 'reactjs-popup';
import { fetchAdvancedAnalytics, fetchAllTradesBasicAnalytics } from '../../redux/aggregation-reducer/aggregation-slice';
import { useAppSelector } from '../../redux/hooks';
import { RequestStatus } from '../../redux/upload-reducer/upload-slice';
import { InvalidFilePopup } from '../Upload/InvalidFilePopup';
import { PopupDisplay } from './AggregatedPopup';
import css from './Trades.module.scss';
import { TradesSummaryTable } from './TradesSummaryTable';


const TradesSummary = () => {
	const dispatch = useDispatch();

	const selectAllSelected = useAppSelector((state) => state.aggregation.allTradesSelected);

	const basicAggregatedAnalytics = useAppSelector((state) => state.aggregation.basicAggregatedAnalytics);

	const decayAggregatedAnalytics = useAppSelector((state) => state.aggregation.decayAggregatedAnalytics);
	const implementationShortfallAggregatedAnalytics = useAppSelector((state) => state.aggregation.implementationShortfallAggregatedAnalytics);

	const basicAggregatedAnalyticsRequestStatus = useAppSelector((state) => state.aggregation.basicAggregatedAnalyticsRequestStatus);
	const advancedTradeLoadingStatus = useAppSelector((state) => state.trades.loadingStatus);

	const [isExpanded, setIsExpanded] = useState(false);
	const [invalidPopupEnabled, setInvalidPopupEnabled] = useState(false);
	

	const handleSelectChange = ({name, option}) => {
		dispatch(fetchAdvancedAnalytics (option.value));
	}

	const handlePopupClose = (e) => {
		document.body.style.overflow = 'auto';
		setIsExpanded(false);
	}

	const buttonText = () => {
		return selectAllSelected ? "DESELECT ALL": "SELECT ALL"
	}

	const isAggregationValid = (aggregation) => {
		if (aggregation.totalTrades === "0" || aggregation.totalTrades === 0) {
			return false;
		}
		return true;
	}

	const SummaryTable = () => {
		if (basicAggregatedAnalyticsRequestStatus === RequestStatus.Loading) {
			return (<ScaleLoader  loading={true} color={'white'} height={'75px'} width={'5px'} />);
		}
		else {
			if (isAggregationValid(basicAggregatedAnalytics)) {
				return (<TradesSummaryTable/>)
			}
			else {
				return (
					<div className={ css.tradesSummary__info }>
						Click on some trades to view analytics
					</div>
				)
			}
		}
	}

	return (
		<>
		<Popup open={isExpanded} onClose={handlePopupClose}><PopupDisplay loading={advancedTradeLoadingStatus} trades={basicAggregatedAnalytics} decay={decayAggregatedAnalytics} implementationShortfall={implementationShortfallAggregatedAnalytics} handleSelectChange={handleSelectChange}/></Popup>
		<Popup open={invalidPopupEnabled} onClose={() => {setInvalidPopupEnabled(false);}}>
			<InvalidFilePopup message={"YOU MUST SELECT SOME TRADES TO ACCESS THE ADVANCED TRADE ANALYTICS VIEW."}/>
		</Popup>
		<div className={ css.tradesSummary }>
			<div className={ css.tradesSummary__title }>
				Selected trades summary
			</div>
			<div className={css.body}>
				<SummaryTable/>
			</div>
			<div style={{marginBottom: '20px', textAlign: 'left'}}>
				<button className={css.tradesSummary__button} onClick={() => {dispatch(fetchAllTradesBasicAnalytics());}}>
									{buttonText()}	
				</button>
				{/* <button
					className={css.tradesSummary__button}
					onClick={()=>{
									if (tradeIds.length && !selectAllSelected > 0) {
										document.body.style.overflow = 'hidden';
										dispatch(fetchAdvancedAggregation('Counterparty'));
										setIsExpanded(true);
									}
									else {
										setInvalidPopupEnabled(true);
									}
								}}>VIEW ADVANCED TRADE ANALYTICS
				</button> */}
				
			</div>
		</div>
		</>
	);
};

export default TradesSummary;
