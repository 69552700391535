import { HorizontalBar } from 'react-chartjs-2';
import css from '../../Chart/Chart.module.scss';
import 'chartjs-plugin-datalabels';
import 'chartjs-plugin-zoom';
import { getHorizontalChartGradients, numberWithCommas } from '../../../utils/helpers';

const HorizontalBarChart = ({ title, data, currencySymbol }) => {
	const chartLabels = data.length ? data.map(({ x }) => x) : [];
	const chartDatapoints1 = data.length ? data.map(({ y }) => parseFloat(y)) : [];
	const textColor = css.textColor;
	const axisColor = css.axisColor;
	const chartData = canvas => {
		const ctx = canvas.getContext('2d');

		const { lineColor, gradients} = getHorizontalChartGradients(ctx, data, true);

		return {
			labels: chartLabels,
			datasets: [
				// {
				// 	label: 'Bloomberg',
				// 	backgroundColor: gradient2,
				// 	borderColor: '#F95986',
				// 	borderWidth: {
				// 		top: 0,
				// 		right: 2,
				// 		bottom: 0,
				// 		left: 2
				// 	},
				// 	data: chartDatapoints2,
				// 	yAxisID: 'bar-y-axis1',
				// },
				{
				label: title,
				backgroundColor: gradients,
				borderColor: lineColor,
				borderWidth: {
					top: 0,
					right: 4,
					bottom: 0,
					left: 4
				},
				data: chartDatapoints1,
				yAxisID: 'bar-y-axis2',
			}
		]
		};
	};

	const chartOptions = {
		tooltips: {
			// yAlign: "center",
			// xAlign: "right",
			mode: 'index',
			intersect: false,
			position: 'nearest',
			callbacks: {
				title: function(tooltipItem, data) {
					return data.datasets[0].label;
				},
				label: function(tooltipItem, data) {
					return tooltipItem.label;
				
				},
				afterLabel: function(tooltipItem, data) {
					return currencySymbol + numberWithCommas(tooltipItem['value']);
				}
			  }
		},
		layout: {
			padding: {
				left: 15,
				right: 50
			}
		},
		bezierCurve: false,
        plugins: { 
            datalabels: {
                display: 'auto',
                color: 'white',
                anchor: 'end',
                align: 'right',
				formatter: function(value) {
					let valueString = String(value);
					if (valueString[0]=== '-'){
						return '-' + currencySymbol + valueString.substring(1).toLocaleString();
					}
					else {
						return currencySymbol + String(value).toLocaleString();
					}
				}
            },
			zoom: {
                pan: {
					enabled: true,
					mode: 'y',
					modifierKey: 'shift',
					

				},
				zoom: {
					mode: 'xy',
					drag: true
				},
            }
        },
		scales: {
			yAxes: [{
				id: 'bar-y-axis2',
				stacked: true,
				categoryPercentage: 1,
				barPercentage: 1,
				barThickness: 'flex',
				gridLines: {
					color: axisColor
				},
				ticks: {
					fontColor: textColor
				}
			}, {
				display: false,
				stacked: true,
				id: 'bar-y-axis1',
				type: 'category',
				categoryPercentage: 1,
				barPercentage: 1,
				offset: true
			}],
			xAxes: [{
				id: 'bar-x-axis1',
				stacked: false,
				gridLines: {
					color: axisColor,
					borderDash: [2]
				},
				ticks: {
					fontColor: textColor,
					beginAtZero: true,
					callback: function (value, index, values) {
						let valueString = String(value);
						if (valueString[0]=== '-'){
							return '-$' + valueString.substring(1).toLocaleString();
						}
						else {
							return '$ ' + String(value).toLocaleString();
						}
					}
				}
			}]
		}
	};

	return (
		<HorizontalBar data={ chartData } options={ chartOptions }/>
	);
};

export default HorizontalBarChart;
