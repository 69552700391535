interface ValidationResponse {
    passedValidation : boolean;
    message: string[];
}

export const isFileUploadValid = (file : PostUploadRequestDataType) : ValidationResponse  => {
    let response = {
        passedValidation: true,
        message: [""] //This has to be initialised this way, otherwise TS determines to be never data type.
    }

    response.message.pop(); //To remove empty element.
    
    response = validateSpacesInFilename(file.filename, response);

    return response;
}

const validateSpacesInFilename = (filename : string, validationResponse : ValidationResponse) : ValidationResponse => {
    if (/\s/.test(filename)) {
        validationResponse.passedValidation = false;
        validationResponse.message.push("Remove spaces from filename");
    }

    return validationResponse;
} 