import React, { useCallback, useEffect, useState } from 'react';
import InputRange from 'react-input-range';
import 'react-input-range/lib/css/index.css';
import cn from 'classnames';
import { FilterHeader } from '../AppSelect/FilterHeader';
import { withTheme } from '../../hoc/withTheme';
import { numberWithCommas } from '../../utils/helpers';

import css from './AppInputRange.module.scss';

const AppInputRange = ({ name, min, max, theme, isReset, onChange, title }) => {
	const [rangeBounds, setRangeBounds] = useState({ value: { min: parseInt(min, 10), max: parseInt(max, 10) } });
	const [rangeValue, setRangeValue] = useState({ value: { min: parseInt(min, 10), max: parseInt(max, 10) } });
	const [reset, setReset] = useState(false);
	const [changed, setChanged] = useState(false);

	const handleChange = value => {
		setRangeValue({ value });

		if(!reset && !isReset) {
			setChanged(true);
		}
	};

	const handleChangeCompleted = useCallback(value => {
		onChange({ name, value });
	}, [name, onChange]);

	useEffect(() => {
		if (isReset) {
			handleChange({ min, max });
		}
	}, [isReset, min, max, handleChange]);

	useEffect(() => {
		if (reset) {
			setReset(false);
			handleChange({ min, max });
		}
	}, [reset]);

	useEffect(() => {
		setRangeBounds({ value: { min: parseInt(min, 10), max: parseInt(max, 10) } });
		setRangeValue({ value: { min: parseInt(min, 10), max: parseInt(max, 10) } })
	},[min, max]);

	const calculateStep = () => {
		const step = Math.floor((rangeBounds.value.max - rangeBounds.value.min)/25);
		return step;
	}

	return (
		<div className={ cn(css.range, theme, 'app-range', 'filter-item') }>
			<FilterHeader title={title} name={name} setReset={setReset} changed={changed} setChanged={setChanged}/>
			<div className={css.body}>
				<div className={css.input}>
					<InputRange
						maxValue={ rangeBounds.value.max }
						minValue={ rangeBounds.value.min }
						step={calculateStep()}
						value={ rangeValue.value }
						onChange={ handleChange }
						onChangeComplete = { handleChangeCompleted }
						formatLabel={ numberWithCommas }
					/>
				</div>	
			</div>
		</div>
	);
};

export default withTheme(AppInputRange);
