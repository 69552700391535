import { APIResponseType, baseApiInstance } from './api';

interface GetEfficiencyMetricsResponseDataType extends APIResponseType {
    currency: string
    efficiencyCharts: Array<EfficiencyChartType>
}

export const chartsAPI = {
    getEfficiencyMetrics() {
        return baseApiInstance().post<GetEfficiencyMetricsResponseDataType>('efficiency-metrics')
            .then(res => res.data)
            .catch(err => console.log(err)) as Promise<GetEfficiencyMetricsResponseDataType>;
    }
};