import React from 'react';
import cn from 'classnames';

import { withTheme } from '../../hoc/withTheme';

import css from './Table.module.scss';

const TableHeader = ({ children, className, ...props }) => (
	<thead
		{ ...props }
		className={ cn(css.table__header, className) }
	>
	{ children }
	</thead>
);

export default withTheme(TableHeader);
