import { useCallback, useEffect, useState } from 'react';
import TimeRangePicker from '@wojtekmaj/react-timerange-picker/dist/entry.nostyle';
import { withTheme } from '../../hoc/withTheme';
import './../AppTimepicker/TimeRangePicker.css';
import css from '../AppDatepicker/AppDatepicker.module.scss';
import { FilterHeader } from '../AppSelect/FilterHeader';

const AppTimepicker = ({
						   title, name, isReset, theme, onChange, onCalendarOpen, onCalendarClose, initialValue, ...otherProps
					   }) => {
	// const [isFocused, setIsFocused] = useState(false);
	const [value, setValue] = useState(null);
	const [reset, setReset] = useState(false);

	const handleChange = useCallback(dates => {
		setValue(dates);
		onChange({ name, dates });
	}, [name, onChange]);

	const setWorkingDay = () => {
		handleChange(['09:00:00', '17:30:00']);
	}

	const setFullDay = () => {
		handleChange(['00:00:00', '23:59:59']);
	}

	useEffect(() => {
		if (isReset || reset) {
			setReset(false)
			handleChange(null);
		}
	}, [isReset, reset, handleChange]);



	return (
		// <div
		// 	className={ cn(css.datepicker, theme, css[theme], 'app-datepicker', 'filter-item', { [css.focus]: isFocused }) }>
			<div className={css.timepickerbox}>
				<FilterHeader title={title} name={name} setReset={setReset}/>
				<div style={{marginTop: '10px'}}>
					<TimeRangePicker
						clockIcon={null}
						disableClock={true}
						clearIcon={null}
						rangeDivider={" - "}
						className={css.timepicker}
						value={value? value : initialValue}
						maxDetail={"second"}
						onChange={handleChange}
						/>
				</div>
				<div style={{marginBottom: '20px'}}>
					<button className={css.timebutton} onClick={setWorkingDay} >WORKING DAY</button>
					<button className={css.timebutton} onClick={setFullDay} >FULL DAY</button>
				</div>
			</div>
	);
};

export default withTheme(AppTimepicker);
