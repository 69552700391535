import cn from 'classnames';
import React from 'react';
import { withTheme } from '../../hoc/withTheme';
import css from './Table.module.scss';

export const Table = ({children, className}) => (
	<div className={ cn(css.table, className) }>
		<table>
			{ children }
		</table>
	</div>
);

export default withTheme(Table);
