import cn from 'classnames';
import { ExportToCsv } from 'export-to-csv';
import { useSelector } from 'react-redux';
import { withTheme } from '../../hoc/withTheme';
import { selectCurrentPageTitle } from '../../redux/app-reducer/app-selector';
import AppSwitchButton from '../AppSwitchButton/AppSwitchButton';
import BarUser from '../BarUser/BarUser';
import ExportButton from '../ExportButton/ExportButton';
import IndicatorStatus from '../IndicatorStatus/IndicatorStatus';
import css from './InfoBar.module.scss';

const BarInfo = ({ title, theme }) => {

	const currentPage = useSelector(selectCurrentPageTitle);

	const isSettingsPage = currentPage?.toLowerCase() === 'settings';
	const isUploadsPage = currentPage?.toLowerCase() === 'upload';

	const showExportButton = isUploadsPage;

	const handleButtonPress = () => {
		if (isUploadsPage) {
			downloadTemplate();
		}
	}

	const downloadTemplate = () => {
		console.log("DOWNLOADING");
		exportCsv();
		
	}

	const exportCsv = () => {

		//let data =  ['Trade Date','Spot Date','Value Date','Trade Type','Trade Start Time','Trade End Time','CUST Side Allocation','Amount','BUY CCY','SELL CCY','Dealt Rate','Counterparty','Client ID'];
		let data = [{
			tradeDate: '19/09/2018',
			spotDate: '',
			valueDate: '12/10/2018',
			tradeType: 'FWD',
			tradeStartTime: '12:30:00',
			tradeEndTime: '',
			customerSideAllocation: 'BUY',
			amount: 450000,
			buyCurrency: 'EUR',
			sellCurrency: 'GBP',
			dealtRate:	0.8875,
			counterparty: 'Name of Counterparty',
			clientId: '191265'
		}];
		const options = {
			filename: 'TCA_HUB_Upload_Template.csv',
			fieldSeparator: ",",
			quoteStrings: '',
			decimalSeparator: ".",
			showLabels: true,
			showTitle: false,
			useTextFile: false,
			useBom: true,
			useKeysAsHeaders: false,
			headers: ['Trade Date','Spot Date','Value Date','Trade Type','Trade Start Time','Trade End Time','CUST Side Allocation','Amount','BUY CCY','SELL CCY','Dealt Rate','Counterparty','Client ID']
			
		};
		const csvExporter = new ExportToCsv(options);
		csvExporter.generateCsv(data);
	};


	
	return (
		<>
			<div className={ cn(css.infoBar, css[theme]) }>
				<h1 data-testid="page-title" className={ cn(css.infoBar__title, css[theme]) }>{ title }</h1>
				{ isSettingsPage && (
					<div className={ css.infoBar__middleLayer }>
						<AppSwitchButton
							// onChange={ handleThemeChange }
							name='theme'
							themeMode
						/>
					</div>
				) }
				{/* { isPowerBiPage && <button onClick={()=>{}}>FULLSCREEN</button>} */}
				{
					showExportButton && <ExportButton  onClickFunction={handleButtonPress} text={'DOWNLOAD TEMPLATE'}/>
				}
				<BarUser/>
				<IndicatorStatus/>
			</div>
		</>
	);
};

export default withTheme(BarInfo);
