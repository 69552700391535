import cn from 'classnames';
import { ReactSVG } from 'react-svg';
import avatarImg from '../../assets/images/svg/avatar.svg';
import { withTheme } from '../../hoc/withTheme';
import { useAppSelector } from '../../redux/hooks';
import css from './UserInfo.module.scss';

const BarUser = ({ theme }) => {

	const name = useAppSelector((state) => state.authentication.userName);

	return (
		<div className={ cn(css.user, css[theme]) }>
			<figure className={ css.user__avatar }>
				<ReactSVG src={ avatarImg }/>
			</figure>
			<div className={ css.user__info }>
				{/* <p className={ cn('text', css.user__infoTitle) }>{ company_name }, { job_title }</p> */}
				<p className={ cn('text', css.user__infoSubtitle) }>{ name }</p>
			</div>
		</div>
	);
};

export default withTheme(BarUser);
