import 'bootstrap/dist/css/bootstrap.min.css';
import { useEffect, useState } from 'react';
import { Popup } from 'reactjs-popup';
import { withTitle } from '../../hoc/withTitle';
import { aggregationActions } from '../../redux/aggregation-reducer/aggregation-slice';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { summaryActions } from '../../redux/summary-reducer/summary-slice';
import { fetchSubmittedReportStatuses, fetchUpload, uploadActions } from '../../redux/upload-reducer/upload-slice';
import Dropzone from './Dropzone';
import { InvalidFilePopup } from './InvalidFilePopup';
import { SubmittedReportStatusBar } from './SubmittedReportStatusBar';
import css from './Upload.module.scss';

const Upload = props => {

    const invalidFilePopupEnabled = useAppSelector((state) => state.upload.invalidFilePopupEnabled);
    const invalidFilePopupMessage = useAppSelector((state) => state.upload.invalidFilePopupMessage);
    const [contentsTable, setContentsTable] = useState([]);
    const dispatch = useAppDispatch();

    const fileNamesDropped = useAppSelector((state) => state.upload.fileNamesDropped);
    const fileContentsDropped = useAppSelector((state) => state.upload.fileContentsDropped);

    const sendFileContents = () => {
        fileNamesDropped.forEach((file, index) => {
            dispatch(fetchUpload({filename: fileNamesDropped[index], body: fileContentsDropped[index]}))
        })

        dispatch(aggregationActions.setReloadNecessary(true));
        dispatch(summaryActions.setReloadNecessary(true));
        dispatch(uploadActions.resetFileContentsDropped());
        dispatch(uploadActions.resetFileNamesDropped());
        setContentsTable([]);
    }

    useEffect(() => {
        console.log(contentsTable);
    },[contentsTable])

    useEffect(() => {
        const interval = setInterval(() => {
            dispatch(fetchSubmittedReportStatuses())
        }
            , 10000);
        return () => {
          clearInterval(interval);
        };
      }, [dispatch]);

	return (
		<div >
			<Dropzone setContentsTable={setContentsTable}/>
            {contentsTable.length > 0 && (<button onClick={sendFileContents} className={ css.upload__submit }>Upload Trade Files</button>)}
            <Popup open={invalidFilePopupEnabled} 
                onClose={() => {
                    dispatch(uploadActions.setInvalidFilePopupEnabled(false));
                    dispatch(uploadActions.resetInvalidFilePopupMessage());
                }
            }><InvalidFilePopup messages={invalidFilePopupMessage}/></Popup>
            {contentsTable.map((contentTable, index) => {
                return (
                    <div className={css.upload__table__div}>
                        <p className={css.upload__table__title}>PREVIEW:   {fileNamesDropped[index]}</p>

                        <div className={css.upload__table__scrollbox}>
                            <table className={css.upload__table}>
                                <thead>
                                    <tr>
                                    {Object.keys(contentTable.data[0]).map((key) => {
                                        return(
                                            <th className={css.upload__table__header}>{key}</th>)
                                    })}
                                    </tr>
                                </thead>
                                <tbody>
                                    {contentTable.data.map((content, rowIndex) => {
                                        return (
                                            <tr className={css.upload__table__row}>
                                            {Object.values(content).map((value) => {
                                                return (<td className={css.upload__table__entry}>{value}</td>)
                                            })}
                                        </tr>)
                                    })}
                                </tbody>
                            </table>
                        </div>
                    </div>
                )
            })}
            <SubmittedReportStatusBar/>
        </div>
	);
};

export default withTitle(Upload);
