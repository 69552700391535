import { useAppSelector } from '../../redux/hooks';
import css from './Trades.module.scss';
import { HeaderCell, Table, TableBody, TableCell, TableHeader, TableRow } from '../../components/Table';
export const TradesSummaryTable = () => {

    const basicAggregatedAnalytics = useAppSelector((state) => state.aggregation.basicAggregatedAnalytics);

    const tradeSummaryFields = [
        { key: 'totalTrades', label: 'Trade Count' },
        { key: 'averageUsdAmount', label: 'Mean USD Amount'},
        { key: 'totalUsdAmount', label: 'Total USD Amount' },
        { key: 'averageUsdCost', label: 'Mean USD Cost'},
        { key: 'totalUsdCost', label: 'Total USD Cost' },
        { key: 'averageUnitCost', label: 'Mean Basis Point Cost' }
    ];
    
    return(
        <Table className={ css.tradesSummary__table }>
            <TableHeader className={ css.tradesSummary__table__header}>
                <TableRow>
                    { tradeSummaryFields.map(item => (
                        <HeaderCell
                            key={ item.key }
                            className={ css.tradesSummary__headerCell }
                        >
                            { item.label }
                        </HeaderCell>
                    )) }
                </TableRow>
            </TableHeader>
            <TableBody className={ css.tradesSummary__table__body }>
                <TableRow>
                    

                        {tradeSummaryFields.map((item) => (
                            <TableCell
                                key={ item.key }
                                className={ css.tradesSummary__cell }
                            >
                                {basicAggregatedAnalytics[item.key] || 'N/A'}
                            </TableCell>
                        ))
                    }
                </TableRow>
            </TableBody>
        </Table>
    )
}