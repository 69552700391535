import {userAPI} from '../../api/user-api';
import {appActions, AppActionsTypes} from '../app-reducer/app-reducer';
import {BaseThunkType, InferActionsTypes} from "../store";

const SET_USER = 'user/SET_USER';
const SET_USER_CREDENTIAL_CHECK_STATUS = 'user/SET_USER_CREDENTIAL_CHECK_STATUS';

const initialState = {
    user: {
        name: '',
        username: '',
        email: '',
        system_level: '',
        renewal_date: '',
        telephone: '',
        company_name: '',
        job_title: '',
        industry: '',
        image: ''
    } as UserType,
    userCredentialCheckStatus: "" as String
};

type InitialStateType = typeof initialState;

const userReducer = (state = initialState, action: UserActionsTypes): InitialStateType => {
    switch (action.type) {
        case SET_USER:
        case SET_USER_CREDENTIAL_CHECK_STATUS:
            return {...state, ...action.payload};
        default:
            return state;
    }
};

export const userActions = {
    setUser: (user: UserType) => ({type: SET_USER, payload: {user}}),
    setUserCredentialCheckStatus: (userCredentialCheckStatus: String) => ({type: SET_USER_CREDENTIAL_CHECK_STATUS, payload: {userCredentialCheckStatus}})
}

type UserActionsTypes = InferActionsTypes<typeof userActions>
type ThunkType = BaseThunkType<UserActionsTypes | AppActionsTypes>

export const fetchUser = (userId: number): ThunkType => async (dispatch) => {
    try {
        const {data, error} = await userAPI.getUser(userId);

        if (!error) {
            dispatch(userActions.setUser(data));
        } else {
            dispatch(appActions.setErrors(error.message));
        }
    } catch (e) {
        console.log(e);
    }
};

export default userReducer;