import React from 'react';

const RankingsChart = () => {
	return (
		<div>

		</div>
	);
};

export default RankingsChart;