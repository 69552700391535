import { Action, applyMiddleware, combineReducers, compose, createStore } from 'redux';
import thunkMiddleware, { ThunkAction } from 'redux-thunk';

import appReducer from './app-reducer/app-reducer';
import appStatusReducer from './app-reducer/app-status-slice';
import filterReducer from './filter-reducer/filter-slice';
import summaryReducer from './summary-reducer/summary-slice';
import userReducer from './user-reducer/user-reducer';
import tradesReducer from './trades-reducer/trades-slice';
import chartsReducer from './charts-reducer/charts-reducer';
import rankingsReducer from './rankings-reducer/rankings-reducer';
import uploadReducer from './upload-reducer/upload-slice';
import aggregationReducer from './aggregation-reducer/aggregation-slice';
import pageReducer from './page-reducer/page-slice';
import authenticationReducer from './authentication-reducer/authentication-slice';
import powerBiReducer from './powerbi-reducer/powerbi-slice';

const reducers = combineReducers({
    app: appReducer,
    appStatus: appStatusReducer,
    filters: filterReducer,
    summary: summaryReducer,
    user: userReducer,
    charts: chartsReducer,
    trades: tradesReducer,
    rankings: rankingsReducer,
    upload: uploadReducer,
    aggregation: aggregationReducer,
    page: pageReducer,
    authentication: authenticationReducer,
    powerbi: powerBiReducer
});

type RootReducerType = typeof reducers;
export type AppStateType = ReturnType<RootReducerType>

type PropertiesTypes<T> = T extends { [key: string]: infer U } ? U:never;
export type InferActionsTypes<T extends { [key: string]: (...args: any[]) => any }> = ReturnType<PropertiesTypes<T>>

export type BaseThunkType<A extends Action = Action, R = Promise<void>> = ThunkAction<R, AppStateType, unknown, A>

// @ts-ignore
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const store = createStore(reducers, composeEnhancers(
        applyMiddleware(thunkMiddleware)
));

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;

export default store;