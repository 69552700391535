import { Line } from 'react-chartjs-2';
import css from '../../Chart/Chart.module.scss';
import { getChartGradients } from '../../../utils/helpers';

const MultiLineChart = ({ title, data, currencySymbol }) => {

	const textColor = css.textColor;
	const axisColor = css.axisColor;

	const chartData = canvas => {
		const ctx = canvas.getContext('2d');
		

        const datasets = (data) => {
            let set = [];
            let numberOfDatasets = data.length;
            let i;
            for (i = 0; i < numberOfDatasets; i++){
                let datapoints = data[i].data;
				let newDatapoints = [];
				var j;
				for (j = 0; j < datapoints.length; j++){
					newDatapoints.push({
						x: Number(datapoints[j].x),
						y: Number(datapoints[j].y).toFixed(2),
					});
				}

                let datatitle = data[i].title;
                let { lineColor, gradient } = getChartGradients(ctx, i, numberOfDatasets);
                set.push({
                    label: datatitle,
                    backgroundColor: gradient,
                    borderColor: lineColor,
                    borderWidth: 1,
                    pointBackgroundColor: lineColor,
                    fill: false,
                    borderDash: [2],
                    data: newDatapoints
                })
            }

            return set;
        }


		return {
			label: "LABEL",
			datasets: datasets(data)
		};
	};

	const chartOptions = {
		responsive: true,
		tooltips: {
			mode: 'index',
			intersect: false,
			callbacks: {
				title: function(tooltipItem, data) {
				  return tooltipItem[0]['xLabel'];
				},
				label: function(tooltipItem, data) {
				  return data.datasets[tooltipItem['datasetIndex']].label + ": " + currencySymbol + tooltipItem['yLabel'];
				},
			  }
		},
		hover: {
			mode: 'nearest',
			intersect: true,
		},
		plugins: {
			datalabels: {
				display: false
			}
		},
		elements: {
			point: {
				radius: 2.5
			},
			line: {
				tension: 0 // disables bezier curves
			}
		},
		scales: {
			xAxes: [{
				gridLines: {
					color: axisColor
				},
				// bounds: 'data',
				type: 'linear',
				ticks: {
					fontSize: 15,
					fontColor: textColor
				}
			}],
			yAxes: [{
				gridLines: {
					color: axisColor,
					borderDash: [2]
				},
				ticks: {
					fontSize: 15,
					fontColor: textColor,
					// beginAtZero: true,
					callback: function (value, index, values) {
						return `${'$' } ${ String(value).toLocaleString() }`;
					}
				}
			}]
		},
	};

	return (
		<Line data={ chartData } options={ chartOptions }/>
	);
};

export default MultiLineChart;
