import React from 'react';
import cn from 'classnames';
import { numberWithCommas } from './../../utils/helpers';
import { withTheme } from '../../hoc/withTheme';

import css from './Table.module.scss';

const TableCell = (props) => {
	const { children, className, commarise } = props;
	const isNull = children=== null;
	const cssString = cn(css.table__cell, className);
	return (<td
		{ ...props }
		className={ cssString }
	>
		{ commarise && (!isNull)? numberWithCommas(children) : children }
		{/* {children} */}
	</td>
)};

export default withTheme(TableCell);
