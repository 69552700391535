import css from './Upload.module.scss';
import { useAppDispatch } from '../../redux/hooks';
import { deleteTradeFile, fetchReportStatuses } from '../../redux/upload-reducer/upload-slice';
import cn from 'classnames';
import { summaryActions } from '../../redux/summary-reducer/summary-slice';
import { aggregationActions } from '../../redux/aggregation-reducer/aggregation-slice';

const SingleFileDeletionConfirmationPopupContent = ({setOpen, file}) => {
    const dispatch = useAppDispatch();
    const cancel = () => {
        setOpen(false);
    }

    const deleteFile = () => {

        dispatch(summaryActions.setReloadNecessary(true));
        dispatch(aggregationActions.setReloadNecessary(true));
        dispatch(deleteTradeFile({fileId: file.id})).then(() => {
            dispatch(fetchReportStatuses());
            dispatch(aggregationActions.setReloadNecessary(true));
            dispatch(aggregationActions.setReloadNecessary(true));
        })
        setOpen(false);
    }

    return (
        <div className={cn(css.deletion_confirmation)}>
            <h1><span>CAUTION:</span> Once this process has been started it cannot be undone.</h1>
            <div className={css.button_box}>
                <button className={css.button} onClick={cancel}>
                    CANCEL
                </button>
                <button className={css.red_button} onClick={deleteFile}>
                    DELETE
                </button>
            </div>
        </div>
    )
}

export default SingleFileDeletionConfirmationPopupContent;