import { APIResponseType, baseApiInstance } from './api';

interface GetTradeFile extends APIResponseType {
    data: string
}

export const downloadsAPI = {
    getTradeFile(uuid: string) {
        return baseApiInstance().get<GetTradeFile>(`download_trade_file?uuid=${uuid}`)
        .then(res => res) as Promise<GetTradeFile>;
    },
};