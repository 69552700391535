import React from 'react';
import cn from 'classnames';
import { ReactSVG } from 'react-svg';

import { withTheme } from '../../hoc/withTheme';

import moonImg from '../../assets/images/svg/moon.svg';
import sunImg from '../../assets/images/svg/sun.svg';

import css from './AppSwitchButton.module.scss';

const AppSwitchButton = ({ name, onChange, text, theme, themeMode }) => {
	return (
		<div className={ cn(css.switchBtn, css[theme], { [css.theme]: themeMode }) }>
			<label className={ css.switchBtn__label }>
				<input
					className={ css.switchBtn__input }
					onChange={ onChange }
					type='checkbox'
					name={ name }
					id={ name }
				/>
				<span className={ css.switchBtn__circle }/>
				{ themeMode && <div className={ cn(css.moon, css.icon, css[theme]) }><ReactSVG src={ moonImg }/></div> }
				{ themeMode && <div className={ cn(css.sun, css.icon, css[theme]) }><ReactSVG src={ sunImg }/></div> }
			</label>
			{ text && (
				<label htmlFor={ name }>
					<p className={ css.switchBtn__text }>{ text }</p>
				</label>
			) }
		</div>
	);
};

export default withTheme(AppSwitchButton);
