import { useState } from 'react';
import { Popup } from 'reactjs-popup';
import { useAppSelector, useAppDispatch } from '../../redux/hooks';
import css from './../Trades/Popup.module.scss';
import { ImageButton } from '../../components/AppButtons';
import svg from '../../assets/images/svg/cross.svg';
import {downloadsAPI} from '../../api/downloads-api';
import { CustomisablePopup } from '../Trades/CustomisablePopup';
import triggerFileDownload from '../../utils/triggerFileDownload';
import SingleFileDeletionConfirmationPopupContent from './SingleFileDeletionConfirmationPopupContent';

export const PastFileSubmissionPopupContent = () => {
    const reportStatuses = useAppSelector((state) => state.upload.reportStatuses);
    
    const [isConfirmationPopupEnabled, setIsConfirmationPopupEnabled] = useState(false);
    const [currentFile, setCurrentFile] = useState("");

    const modalOverlayStyle = {
        backdropFilter: "blur(5px)"
    }

    const handleRequestTradeFile =  async (uuid, fileName) => {
        try {
            const { data } = await downloadsAPI.getTradeFile(uuid);
            const csv = new Blob([data], {type: 'text/csv'});
            const url = URL.createObjectURL(csv);
            triggerFileDownload(url, `${fileName.split('.')[0]}-output.csv`);
        } catch (e) {
            console.error(e);
        }
    }

    return (
        <>
            <Popup open={isConfirmationPopupEnabled} onClose={() => {setIsConfirmationPopupEnabled(false);}} 
            overlayStyle={modalOverlayStyle}
            closeOnEscape={false} closeOnDocumentClick={false}>
                <CustomisablePopup title={"CONFIRM DELETION OF FILE: " + currentFile.filename} classname={css.dark}>
                    <SingleFileDeletionConfirmationPopupContent setOpen={setIsConfirmationPopupEnabled} file={currentFile}/>
                </CustomisablePopup>
            </Popup>
            {reportStatuses.length > 0 ? (<table className={css.table}>
                <thead>
                    <tr>
                        <th>
                            Submission Time
                        </th>
                        <th>
                            Filename
                        </th>
                        <th>
                            Job ID
                        </th>
                        <th>
                            Status
                        </th>
                        <th>
                            No. of Lines
                        </th>
                        {/* <th>
                        </th> */}
                        <th>
                        </th>
                    </tr>
                </thead>
                <tbody>
                    {reportStatuses.map((reportStatus) => {
                        return (
                            <tr key={reportStatus.uuid}>
                                <td>
                                    {reportStatus.submittedAt}
                                </td>
                                <td>
                                    {reportStatus.filename}
                                </td>
                                <td>
                                    {reportStatus.uuid}
                                </td>
                                <td>
                                    {reportStatus.status}
                                </td>
                                <td>
                                    {reportStatus.noOfLines}
                                </td>
                                <td className={css.download}>
                                    { reportStatus.status === 'Complete' && <button className={css.download__button} onClick={() => handleRequestTradeFile(reportStatus.uuid, reportStatus.filename)}>Download</button>}
                                </td>
                                <td onClick={()=>{
                                    setIsConfirmationPopupEnabled(true);
                                    setCurrentFile(reportStatus);
                                }}>
                                    <ImageButton className={ css.fullScreen_button } style={{pointerEvents: 'all'}} svg={svg} svgClass={css.popup__cross} />
                                </td>
                            </tr>)
                    })}
                </tbody>
            </table>)
            :
            (
                <div className={css.empty_message_box}>
                    <p className={css.empty_message}>YOU HAVE NOT UPLOADED ANY FILES.</p>
                </div>
            )}
        </>
    )
}