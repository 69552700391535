import { APIResponseType, baseApiInstance } from './api';

interface GetUserResponseDataType extends APIResponseType {
	data: UserType
}

interface GetCredentialCheckResponseDataType extends APIResponseType {
	Status: String
}

export const userAPI = {
	getUser(userId: number) {
		return baseApiInstance().get<GetUserResponseDataType>(`user?user_id=${ userId }`)
							  .then(res => res.data)
							  .catch(err => console.log(err)) as Promise<GetUserResponseDataType>;
	},
	getCredentialCheck() {
		return baseApiInstance().get<GetCredentialCheckResponseDataType>("credential_check")
								.then(res => res.data)
							//   .catch(err => console.log(err)) as Promise<GetCredentialCheckResponseDataType>;
	}
};