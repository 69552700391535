import React from 'react';
import cn from 'classnames';
import { compose } from 'redux';

import EfficiencyFilters from './EfficiencyFilters';
import EfficiencyCharts from './EfficiencyCharts';
import { withTitle } from '../../hoc/withTitle';
import { withTheme } from '../../hoc/withTheme';

import css from './EfficiencyMetrics.module.scss';

const EfficiencyMetrics = ({ theme }) => {

	return (
		<div className={ cn(css.efficiency, css[theme]) }>
			<EfficiencyFilters/>
			<EfficiencyCharts/>
		</div>
	);
};

export default compose(withTitle, withTheme)(EfficiencyMetrics);
