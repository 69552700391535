import React from 'react';
import { useSelector } from 'react-redux';

import { selectErrors, selectSuccessMessages } from '../../redux/app-reducer/app-selector';

import Error, {SuccessMessage} from './Error';

import css from './Error.module.scss';

const Errors = () => {
	const errors = useSelector(selectErrors);

	return (
		<div className={ css.errors }>
			{ errors.map(error => <Error key={ error } error={ error }/>) }
		</div>
	);
};

export const SuccessMessages = () => {
	const successMessages = useSelector(selectSuccessMessages);

	return (
		<div className={ css.errors }>
			{ successMessages.map(success => <SuccessMessage key={ success } success={success}/>) }
		</div>
	);
};

export default Errors;
