import { useCallback, useEffect, useState } from 'react';
import Select, { components } from 'react-select';
import cn from 'classnames';
import { ReactSVG } from 'react-svg';

import { withTheme } from '../../hoc/withTheme';

import dropdownImg from '../../assets/images/svg/dropdown.svg';

import css from '../../routes/Trades/Popup.module.scss';

const AppSelectTradesPage = ({ theme, title, options, isReset, handleSelect, name }) => {
	const [selectedOption, setSelectedOption] = useState(null);
	const [isFocused, setIsFocused] = useState(false);
	const selectStyles = getSelectStyles(theme);

	const handleChangeSelect = useCallback(option => {
		setSelectedOption(option);
		handleSelect({name: name, option: option});
	}, [name, handleSelect]);

	const handleOnMenuOpen = () => {
		setIsFocused(true);
	};

	const handleOnMenuClose = () => {
		setIsFocused(false);
	};

	useEffect(() => {
		if (isReset) {
			handleChangeSelect(null);
		}
	}, [isReset, handleChangeSelect]);

	return (
		<div className={ cn(css.popup__select, { [css.focus]: isFocused }) }>
			{/* <p className={ cn(css.popup__selectTitle) }>{ title }</p> */}
			<Select
				value={ selectedOption }
				onChange={ handleChangeSelect }
				onMenuOpen={ handleOnMenuOpen }
				onMenuClose={ handleOnMenuClose }
				options={ options.map(({ title, value }) => ({ label: title, value })) }
				placeholder='Dependent Variable'
				isSearchable={ false }
				styles={ selectStyles }
				components={ { DropdownIndicator } }
			/>
		</div>
	);
};

const CaretDownIcon = () => {
	return <ReactSVG src={ dropdownImg }/>;
};

const DropdownIndicator = props => {
	return (
		<components.DropdownIndicator { ...props }>
			<CaretDownIcon/>
		</components.DropdownIndicator>
	);
};

const getSelectStyles = theme => {
	const lightTheme = theme === 'light';

	return {
		control: (styles, { isFocused }) => ({
			...styles,
			backgroundColor: isFocused ? lightTheme ? 'rgba(0,0,0,0.1)' : '#435d8e' : lightTheme ? '#fff' : '#375180',
			border: 'none',
			boxShadow: 'none',
			padding: '10px 0 10px',
			cursor: 'pointer',
			transition: 'all .35s',
			'&:hover': {
				backgroundColor: lightTheme ? 'rgba(255,0,0,0.1)' : '#435d8e'
			}
		}),
		placeholder: styles => ({
			...styles,
			color: lightTheme ? '#353952' : '#D1D2D7',
			fontFamily: 'Rubik',
			fontSize: '14px'
		}),
		valueContainer: styles => ({
			...styles,
			paddingLeft: '24px'
		}),
		singleValue: styles => ({
			...styles,
			color: lightTheme ? '#353952' : '#ffffff',
			fontFamily: 'Rubik',
			fontSize: '14px'
		}),
		indicatorSeparator: styles => ({
			...styles,
			display: 'none'
		}),
		indicatorsContainer: styles => ({
			...styles,
			marginRight: '15px'
		}),
		indicatorContainer: styles => ({
			...styles,
			padding: 0,
			height: '20px'
		}),
		menu: styles => ({
			...styles,
			backgroundColor: lightTheme ? '#AEB5C3' : '#0A0E23',
			margin: 0,
			zIndex: 2
		}),
		menuList: styles => ({
			...styles,
			padding: 0
		}),
		option: (styles, { isSelected }) => ({
			...styles,
			paddingTop: '20px',
			paddingBottom: '20px',
			paddingLeft: '24px',
			textAlign: 'left',
			color: lightTheme ? '#353952' : '#ffffff',
			fontFamily: 'Rubik',
			fontSize: '14px',
			fontWeight: '300',
			cursor: 'pointer',
			transition: 'all .1s',
			backgroundColor: isSelected ? lightTheme ? 'rgba(0,0,0,.3)' : 'rgba(18, 22, 45, 0.6)' : lightTheme ? '#AEB5C3' : '#0A0E23',

			'&:hover': {
				backgroundColor: lightTheme ? 'rgba(0,0,0,.3)' : '#60C1FD'
			}
		})
	};
};

export default withTheme(AppSelectTradesPage);
