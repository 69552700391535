import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { tradesAPI } from '../../api/trades-api'

enum RequestStatus {
    Loading,
    Accepted,
    Rejected
}

interface TradesState {
    decay: Array<DecayChartDatapointType>,
    implementationShortfall: Array<DecayChartType>
    loadingStatus: RequestStatus
}

const initialState = {
    decay: [],
    implementationShortfall: [],
    loadingStatus: RequestStatus.Loading,
} as TradesState

export const fetchAnalytics = createAsyncThunk('trades/fetchDecay', async (tradeId: number) => {
    const response = await tradesAPI.getDecayChart(tradeId);
    return response;
});

const tradesSlice = createSlice({
  name: 'trades',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder
        .addCase(fetchAnalytics.fulfilled, (state, action) => {
            state.decay = action.payload.decayData.data; //This is wrong
            state.implementationShortfall = action.payload.implementationShortfallData;
            state.loadingStatus = RequestStatus.Accepted;
        })
        .addCase(fetchAnalytics.pending, (state, action) => {
            state.loadingStatus = RequestStatus.Loading;
        })
        .addCase(fetchAnalytics.rejected, (state, action) => {
            state.loadingStatus = RequestStatus.Rejected;
        })
    }
})

export const tradesActions = tradesSlice.actions;
export default tradesSlice.reducer;