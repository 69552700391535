import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { tradesAPI } from "../../api/trades-api";
import { filterActions } from "../filter-reducer/filter-slice";
import { RootState } from "../store";
import { pageActions } from "../page-reducer/page-slice";
import { appStatusActions } from "../app-reducer/app-status-slice";

export enum RequestStatus {
  Loading,
  Accepted,
  Rejected,
}

interface AggregationState {
  reloadNecessary: boolean;
  tradeTable: TradeTableType;
  tradeTableLoadingStatus: RequestStatus;

  selectedTradeIds: Array<number>;
  allTradesSelected: boolean;
  basicAggregatedAnalytics: AggregationSummaryType;

  basicAggregatedAnalyticsRequestStatus: RequestStatus;
  decayAggregatedAnalytics: Array<DecayChartType>;
  implementationShortfallAggregatedAnalytics: Array<DecayChartType>;
}

const initialState = {
  reloadNecessary: false,
  tradeTable: {
    title: "Trades",
    data: [],
    totalTrades: 0,
  },
  tradeTableLoadingStatus: RequestStatus.Loading,
  selectedTradeIds: [],
  allTradesSelected: false,
  basicAggregatedAnalytics: {
    totalTrades: 0,
    totalUsdAmount: 0,
    averageUsdAmount: 0,
    totalUsdCost: 0,
    averageUsdCost: 0,
    averageUnitCost: 0,
    totalPAndLAtThirtySeconds: 0,
    averageUnitCostAtThirtySeconds: 0,
    averageUnitCostOfVolatility: 0,
    averageUsdCostOfVolatility: 0,
    averageTemporaryPriceImpact: 0,
    averagePermanentPriceImpact: 0,
  },
  basicAggregatedAnalyticsRequestStatus: RequestStatus.Accepted,
  decayAggregatedAnalytics: [],
  implementationShortfallAggregatedAnalytics: [],
} as AggregationState;

export const fetchTrades = createAsyncThunk(
  "aggregation/fetchTrades",
  async (filters: FilterRequestType[], thunkApi) => {
    const requestId = Math.floor(Math.random() * 10000000);

    const state = thunkApi.getState() as RootState;
    const selectedAllTrades = state.aggregation.allTradesSelected;
    const selectedTrades = state.aggregation.selectedTradeIds;
    const pageNumber = state.page.pageNumber;
    const numberOfTradesPerPage = state.page.numberOfRecordsPerPage;

    thunkApi.dispatch(
      aggregationActions.setTradeTableLoadingStatus(RequestStatus.Loading)
    );

    thunkApi.dispatch(filterActions.setPendingRequest(requestId));
    const response = await tradesAPI.getTrades({
      filters,
      pageNumber,
      numberOfTradesPerPage,
      selectedTrades,
      selectedAllTrades,
    });
    if (response) {
      thunkApi.dispatch(appStatusActions.setBackendConnected());
      thunkApi.dispatch(aggregationActions.tradesLoaded(response.tradeTable));
      if (response.filters.length > 0) {
        thunkApi.dispatch(filterActions.setFilters(response.filters));
      }
      thunkApi.dispatch(
        pageActions.setTotalNumberOfTrades(response.tradeTable.totalTrades)
      );
      thunkApi.dispatch(filterActions.removePendingRequest(requestId));
      return response;
    } else {
      thunkApi.dispatch(appStatusActions.setBackendDisconnected());
      return thunkApi.rejectWithValue("REJECTED");
    }
  }
);

export const fetchBasicAnalytics = createAsyncThunk(
  "aggregation/fetchAggregation",
  async (selectedTrade: TradeTableDataType, thunkApi) => {
    thunkApi.dispatch(aggregationActions.tradeSelected(selectedTrade));

    const state = thunkApi.getState() as RootState;
    const selectAllSelected = state.aggregation.allTradesSelected;
    const filters = state.filters.filterRequests;
    const tradeIds = state.aggregation.selectedTradeIds;

    const response = await tradesAPI.getSelectedTradeAggregation(
      { filters, tradeIds },
      selectAllSelected
    );
    return response;
  }
);

export const fetchAllTradesBasicAnalytics = createAsyncThunk(
  "aggregation/fetchAllTradesAggregation",
  async (firstArg: void, thunkApi) => {
    thunkApi.dispatch(aggregationActions.allTradesSelected());

    const state = thunkApi.getState() as RootState;
    const selectAllSelected = state.aggregation.allTradesSelected;
    const filters = state.filters.filterRequests;
    const tradeIds = state.aggregation.selectedTradeIds;

    const response = await tradesAPI.getSelectedTradeAggregation(
      { filters, tradeIds },
      selectAllSelected
    );
    return response;
  }
);

export const fetchAdvancedAnalytics = createAsyncThunk(
  "aggregation/fetchAdvancedAggregation",
  async (dependentVariable: string, thunkApi) => {
    const state = thunkApi.getState() as RootState;
    const tradeIds = state.aggregation.selectedTradeIds;

    const response = await tradesAPI.postAggregatedTradeSummary({
      tradeIds,
      dependentVariable,
    });

    return response;
  }
);

const aggregationSlice = createSlice({
  name: "aggregation",
  initialState,
  reducers: {
    resetBasicAggregatedAnalytics(state) {
      state.basicAggregatedAnalytics = initialState.basicAggregatedAnalytics;
    },
    setTradeTableLoadingStatus(state, action: PayloadAction<RequestStatus>) {
      state.tradeTableLoadingStatus = action.payload;
    },
    tradesLoaded(state, action: PayloadAction<TradeTableType>) {
      state.tradeTable = action.payload;
      state.tradeTableLoadingStatus = RequestStatus.Accepted;
      if (state.allTradesSelected) {
        state.tradeTable.data.forEach((t) => {
          t.isSelected = true;
        });
        for (let i = 0; i < state.tradeTable.data.length; i++) {
          for (let j = 0; j < state.selectedTradeIds.length; j++) {
            if (
              Number(state.tradeTable.data[i].tradeId) ===
              state.selectedTradeIds[j]
            ) {
              state.tradeTable.data[i].isSelected = false;
            }
          }
        }
      } else {
        for (let i = 0; i < state.tradeTable.data.length; i++) {
          for (let j = 0; j < state.selectedTradeIds.length; j++) {
            if (
              Number(state.tradeTable.data[i].tradeId) ===
              state.selectedTradeIds[j]
            ) {
              state.tradeTable.data[i].isSelected = true;
            }
          }
        }
      }
    },
    tradeSelected(state, action: PayloadAction<TradeTableDataType>) {
      state.basicAggregatedAnalytics = initialState.basicAggregatedAnalytics;
      if (state.allTradesSelected) {
        if (action.payload.isSelected) {
          state.selectedTradeIds.push(Number.parseInt(action.payload.tradeId));
        } else {
          state.selectedTradeIds = state.selectedTradeIds.filter(
            (id) => id !== Number.parseInt(action.payload.tradeId)
          );
        }
      } else {
        if (action.payload.isSelected) {
          state.selectedTradeIds = state.selectedTradeIds.filter(
            (id) => id !== Number.parseInt(action.payload.tradeId)
          );
        } else {
          state.selectedTradeIds.push(Number.parseInt(action.payload.tradeId));
        }
      }
      for (let i = 0; i < state.tradeTable.data.length; i++) {
        if (action.payload.tradeId === state.tradeTable.data[i].tradeId) {
          state.tradeTable.data[i].isSelected =
            !state.tradeTable.data[i].isSelected;
        }
      }
    },
    allTradesSelected(state) {
      state.allTradesSelected = !state.allTradesSelected;
      state.selectedTradeIds = [];
      if (state.allTradesSelected) {
        state.tradeTable.data.forEach((t) => {
          t.isSelected = true;
        });
      } else {
        state.tradeTable.data.forEach((t) => {
          t.isSelected = false;
        });
      }
    },
    setReloadNecessary(state, action: PayloadAction<boolean>) {
      state.reloadNecessary = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchTrades.fulfilled, (state, action) => {
        state.tradeTableLoadingStatus = RequestStatus.Accepted;
      })
      .addCase(fetchTrades.rejected, (state, action) => {
        state.tradeTableLoadingStatus = RequestStatus.Rejected;
      })
      .addCase(fetchBasicAnalytics.pending, (state, action) => {
        state.basicAggregatedAnalyticsRequestStatus = RequestStatus.Loading;
      })
      .addCase(fetchBasicAnalytics.fulfilled, (state, action) => {
        state.basicAggregatedAnalyticsRequestStatus = RequestStatus.Accepted;
        state.basicAggregatedAnalytics = action.payload.aggregation;
      })
      .addCase(fetchBasicAnalytics.rejected, (state, action) => {
        state.basicAggregatedAnalyticsRequestStatus = RequestStatus.Rejected;
      })
      .addCase(fetchAllTradesBasicAnalytics.pending, (state, action) => {
        state.basicAggregatedAnalyticsRequestStatus = RequestStatus.Loading;
      })
      .addCase(fetchAllTradesBasicAnalytics.fulfilled, (state, action) => {
        state.basicAggregatedAnalyticsRequestStatus = RequestStatus.Accepted;
        state.basicAggregatedAnalytics = action.payload.aggregation;
      });
  },
});

export const aggregationActions = aggregationSlice.actions;
export const { tradeSelected } = aggregationSlice.actions;
export default aggregationSlice.reducer;
