import { chartsAPI } from '../../api/charts-api';
import { appActions, AppActionsTypes } from '../app-reducer/app-reducer';
import { BaseThunkType, InferActionsTypes } from "../store";

const SET_EFFICIENCY_CHARTS = 'tables/SET_EFFICIENCY_CHARTS';
const SET_EFFICIENCY_CHARTS_CURRENCY = 'tables/SET_EFFICIENCY_CHARTS_CURRENCY';
const ADD_EXPORT_LINE_CHART = 'tables/ADD_EXPORT_LINE_CHART';

const initialState = {
    efficiencyChartsCurrency: "",
    efficiencyCharts: [] as Array<EfficiencyChartType>
};

type InitialStateType = typeof initialState;

const chartsReducer = (state = initialState, action: ChartsActionsTypes): InitialStateType => {
    switch (action.type) {
        case SET_EFFICIENCY_CHARTS:
        case SET_EFFICIENCY_CHARTS_CURRENCY:
        case ADD_EXPORT_LINE_CHART:
            return { ...state, ...action.payload };
        default:
            return state;
    }
};

export const chartsActions = {
    setEfficiencyCharts: (efficiencyCharts: Array<EfficiencyChartType>) => ({
        type: SET_EFFICIENCY_CHARTS,
        payload: { efficiencyCharts }
    }),
    setEfficiencyChartsCurrency: (efficiencyChartsCurrency: string) => ({
        type: SET_EFFICIENCY_CHARTS_CURRENCY,
        payload: { efficiencyChartsCurrency }
    })
};

type ChartsActionsTypes = InferActionsTypes<typeof chartsActions>
type ThunkType = BaseThunkType<ChartsActionsTypes | AppActionsTypes>

export const fetchEfficiencyCharts = (): ThunkType => async (dispatch) => {
    try {
        const { efficiencyCharts, currency, error } = await chartsAPI.getEfficiencyMetrics();

        if (!error) {
            dispatch(chartsActions.setEfficiencyCharts(efficiencyCharts));
            dispatch(chartsActions.setEfficiencyChartsCurrency(currency));
        } else {
            dispatch(appActions.setErrors(error.message));
        }
    } catch (e) {
        console.log(e);
    }
};

export default chartsReducer;