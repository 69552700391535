import cn from 'classnames';
import { useEffect, useRef, useState } from 'react';
import ReactNotification from 'react-notifications-component';
import 'react-notifications-component/dist/theme.css';
import { useSelector } from 'react-redux';
import { Route, Switch } from 'react-router-dom';
import BarInfo from './components/BarInfo/BarInfo';
import Errors, { SuccessMessages } from './components/Error/Errors';
import Header from './components/Header/Header';
import { withTheme } from './hoc/withTheme';
import { appActions } from './redux/app-reducer/app-reducer';
import { selectCurrentPageTitle, selectIsFilterActive } from './redux/app-reducer/app-selector';
import { fetchStateInfo } from './redux/app-reducer/app-status-slice';
import { authenticateUser, authenticationActions, fetchJwtToken } from './redux/authentication-reducer/authentication-slice';
import { useAppDispatch, useAppSelector } from './redux/hooks';
import Alerts from './routes/Alerts/Alerts';
import Authenticate from './routes/Authenticate/Authenticate';
import EfficiencyMetrics from './routes/EfficiencyMetrics/EfficiencyMetrics';
import PowerBi from './routes/PowerBi/PowerBi';
import Rankings from './routes/Rankings/Rankings';
import Settings from './routes/Settings/Settings';
import Summary from './routes/Summary/Summary';
import Trades from "./routes/Trades/Trades";
import Upload from "./routes/Upload/Upload";
import Maintenance from "./routes/Maintenance/Maintenance";
import './scss/App.scss';
import { useWindowWidth } from './utils/custom-hooks';

function App({ theme }) {
	const jwt = useAppSelector((state) => state.authentication.jwt);
	const jwtStored = useAppSelector((state) => state.authentication.jwtStored);
	
	const isFilterActive = useSelector(selectIsFilterActive);
	const currentPageTitle = useSelector(selectCurrentPageTitle);
	const windowWidth = useWindowWidth();

	const isUserAuthenticated = useAppSelector((state) => state.authentication.isUserAuthenticated);
	const isUserLockedOut = useAppSelector((state) => state.authentication.isUserLockedOut);

	const developmentSizingColours = process.env.REACT_APP_SIZING_COLOURS === 'true';
	const isMaintenanceMode = process.env.REACT_APP_MAINTENANCE_MODE === 'enabled';
	const dispatch = useAppDispatch();
	
	const [jwtRequestSent, setJwtRequestSent] = useState(false);

	const USER_EMAIL = process.env.REACT_APP_USER_EMAIL || '';
	const isDevelopmentMode = USER_EMAIL !== "";
	// const isDevelopmentMode = false;

	if (isDevelopmentMode) {
		if (!jwtStored) {
			if (!jwtRequestSent) {
				setJwtRequestSent(true);
				dispatch(fetchJwtToken());
			}

			console.log("Redux jwt: " + jwt);
			const sessionJwt = sessionStorage.getItem('jwt');
			console.log("Session jwt: " + sessionJwt)
			
		}
		else {
			if (!isUserAuthenticated)
				dispatch(authenticationActions.setIsUserAuthenticated(true));
		}
		
	}
	else {
		if (!isUserAuthenticated && !isUserLockedOut) {
			if (!jwtStored) {
				const sessionJwt = sessionStorage.getItem('jwt');
				if (sessionJwt){
					dispatch(authenticationActions.setJwt(sessionJwt));
				} 
				else {
					const token = window.location.pathname.substring(1);
					
					if (token.length > 0) {
						dispatch(authenticationActions.setJwt(token));
							window.location.pathname = "/";
					}
				}

				dispatch(authenticateUser());
			}
		}
	}
	
	const filterRequests = useAppSelector((state) => state.filters.filterRequests);
	
	const pastFilterRequestsTrades = useRef(undefined);
	const pastFilterRequestsSummary = useRef(undefined);

	useEffect(() => {
		if (isUserAuthenticated) {
			dispatch(fetchStateInfo());
		}

		const theme = localStorage.getItem('theme') || '';
		dispatch(appActions.setTheme(theme));
	}, [dispatch, isUserAuthenticated]);

	if(isMaintenanceMode) {
		return (
			<div className={ cn('App', theme) }>
			{ windowWidth >= 1440 && <Errors/> }
			<main className={ cn('App-main', {'App__development': developmentSizingColours}, { 'active-filter': isFilterActive }) }>
				<div className='container'>
				<Maintenance/>
				</div>
				</main>
			</div>
		)
	}

	return (
		<div className={ cn('App', theme) }>
			<ReactNotification />
			<Header/>
			{ windowWidth >= 1440 && <Errors/> }
			<main className={ cn('App-main', {'App__development': developmentSizingColours}, { 'active-filter': isFilterActive }) }>
				<div className='container'>
					
					{isUserAuthenticated ? (
						<>
							<BarInfo title={ currentPageTitle }/>
							<Switch>
								<Route exact path='/' render={ _ => <Summary title='TCA Summary' filterRequests={filterRequests} pastFilterRequests={pastFilterRequestsSummary}/> }/>
								<Route path='/efficiency-metrics' render={ _ => <EfficiencyMetrics title='Efficiency Metrics' /> }/>
								<Route path='/alerts' render={ _ => <Alerts title='Alerts' /> }/>
								{/* <Route path='/reports' render={ _ => <Reports title='Reports' /> }/> */}
								<Route path='/settings' render={ _ => <Settings title='Settings' /> }/>
								<Route path='/trades' render={ _ => <Trades title='Trades' filterRequests={filterRequests} pastFilterRequests={pastFilterRequestsTrades}/> }/>
								<Route path='/rankings' render={ _ => <Rankings title='Rankings' /> }/>
								<Route path='/upload' render={ _ => <Upload title='Upload' /> }/>
								<Route path='/reports' render={ _ => <PowerBi title='Reports'/>}/>
							</Switch>
							{ windowWidth < 1440 && <Errors/> }
							<SuccessMessages/>
						</>
						) : (						
							<Authenticate title='Authenticate' lockout={isUserLockedOut}/>
						)}
				</div>
			</main>
		</div>
	);
}

export default withTheme(App);
