
import cn from 'classnames';
import { withTheme } from '../../hoc/withTheme';

import css from './Table.module.scss';

const TableBody = ({ children, className, ...props }) => {
	
	const cssString = cn(css.table__body, className);
	
	return (
		<tbody { ...props } className={ cssString }>
		{/* <tbody { ...props } className={ cn(className) }> */}
			{ children }
		</tbody>
	);

};

export default withTheme(TableBody);