import React from 'react';
import { ReactSVG } from 'react-svg';
import { useDispatch } from 'react-redux';
import cn from 'classnames';

import { appActions } from '../../redux/app-reducer/app-reducer';
import { withTheme } from '../../hoc/withTheme';

import removeImg from '../../assets/images/svg/remove.svg';

import css from './Error.module.scss';

const Error = ({ error, theme }) => {
	const dispatch = useDispatch();

	const handleRemove = () => {
		dispatch(appActions.removeError(error));
	};

	return (
		<div className={ cn(css.error, css[theme]) }>
			<p>{ error }</p>
			<span className={ css.error__close } onClick={ handleRemove }>
                <ReactSVG src={ removeImg }/>
            </span>
		</div>
	);
};

export const SuccessMessage = ({ success, theme }) => {
	const dispatch = useDispatch();

	const handleRemove = () => {
		dispatch(appActions.removeSuccessMessages(success));
	};

	return (
		<div className={ cn(css.successmessage, css.error, css[theme]) }>
			<p>{ success }</p>
			<span className={ css.error__close } onClick={ handleRemove }>
                <ReactSVG src={ removeImg }/>
            </span>
		</div>
	);
};

export default withTheme(Error);
