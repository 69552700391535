import Chart from '../../components/Chart/Chart';
import { useAppSelector } from '../../redux/hooks';
import { PopupSubBox } from './PopupSubBox';
import css from './Popup.module.scss';

export const BasicAnalyticsPopupContent = ({selectedRow}) => {

    const decay = useAppSelector((state) => state.trades.decay);
	const implementationShortfall = useAppSelector((state) => state.trades.implementationShortfall);
    
    return (
    <>
        {decay && decay.length > 0 ? (
        
        <PopupSubBox title={"Decay"} largeAsDefault>
            <Chart
                        title={ "Decay" }
                        data={ [{data: decay, title: "Decay"}] }
                        // onClick={ handleChartClick }
                        type='multiLine'
                        popup={true}
                        currencySymbol={"$"}
            />
        </PopupSubBox>
        
        ):(<></>)}        
        <PopupSubBox title={"P&L"} compact>
            <p><span>Price at 30 Seconds:</span> ${selectedRow.pAndLAtThirtySeconds}</p>
        </PopupSubBox>
        {/* <PopupSubBox title={"Price Impact"} compact>
            <p><span>Temporary Impact:</span> {selectedRow.temporaryPriceImpact}</p>
            <p><span>Permanent Impact:</span> {selectedRow.permanentPriceImpact}</p>
        </PopupSubBox> */}
        <PopupSubBox title={"TCA"} compact>
            <p><span>USD Cost:</span> ${selectedRow.usdCost}</p>
            <p><span>BP Cost:</span> {selectedRow.bpCost}</p>
            <p><span>NCFX Benchmark:</span> ${selectedRow.benchmarkRate}</p>
        </PopupSubBox>
        {/* <PopupSubBox title={"Cost of Volatility"} compact>
            <p><span>USD Cost of Volatility:</span> ${selectedRow.usdCostOfVolatility}</p>
            <p><span>Unit Cost of Volatility:</span> {selectedRow.unitCostOfVolatility}</p>
        </PopupSubBox> */}
        {implementationShortfall && implementationShortfall.length > 0 ? (
        <PopupSubBox title={"Implementation Shortfall"} className={css.subbox} largeAsDefault>
            <Chart
            title={ 'Implementation Shortfall' }
            data={ implementationShortfall }
            // onClick={ handleChartClick }
            type='bar'
            popup={true}
            />
        </PopupSubBox>
        
        ) : (<></>)}
    </>)
}